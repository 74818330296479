import React, { useEffect, useState, } from "react";
import { notification, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { FormatEuro, calculateTotal, } from '../../../components/helpers';
import ProductGotoShoppingcartModal from '../detail.views/product.goto.shoppingcart.modal';


const ProductWebshopSideBarView = ({
    data,
    selectedCategory,
    options,
    history,
    ...props
}) => {

    const [modalIsOpen, modalIsOpenHandler] = useState(false);
    const [productAmount, setProductAmount] = useState(data.product_min_order_quantity);
    const [productTotalPrice, setProductTotalPrice] = useState(0);
    const { triggerShoppingCart, language, } = props;
    const intl = useIntl();

    useEffect(() => {
        if (productAmount < 1) { setProductAmount(1) }
        // eslint-disable-next-line
    }, [productAmount]);

    // trigger calculateTotal
    useEffect(() => {

        const record = {
            product_category: selectedCategory,
            // selected_options: selectedOptions,
            // possible_options: options,
            // date_range: selectedDate,
            days_free_of_charge: [],
            bookdetail_amount: productAmount,
            product_data: data,
            minimal_24hours_order: false,
        }

        const results = calculateTotal(record);
        setProductTotalPrice(results.total_price);


        // eslint-disable-next-line
    }, [productAmount, selectedCategory]);


    const addToShoppingcart = () => {

        if (productAmount < data.product_min_order_quantity) {
            notification['warning']({
                message: intl.formatMessage({ id: "order-to-small" }),
                description: intl.formatMessage({ id: "order-to-small-info" }),
            });
            return false;
        }

        const shoppingcart = {
            product_data: data,
            product_category: selectedCategory,
            bookdetail_amount: productAmount,
        };

        triggerShoppingCart(shoppingcart);
        modalIsOpenHandler(true);
    };


    return (

        <div className="">
            {data && (<>
                <h4 className="hover-primary mt-2">
                    <FormattedMessage id="order-now" /> {data.product_names?.[`product_name_${language}`] || data.product_names?.product_name_nl || data.product_name}
                </h4>

                <div className=""></div>

                <div className=" mb-2 text-center">
                    <h4><FormattedMessage id="total" /> <FormatEuro amount={productTotalPrice} /></h4>
                </div>

                <div className="text-center">
                    {data.product_min_order_quantity > 1 && (
                        <div style={{ color: "red" }} className="mt-2">LET OP: Dit product heeft een minimale afnamen van: {data.product_min_order_quantity}</div>
                    )}
                </div>

                <div className="other__options__add mt-4">
                    <div className="item__quantity">
                        {/* Amount */}
                        <button type="button"><span onClick={e => setProductAmount(productAmount - 1)}>-</span>
                            {productAmount}
                            <span onClick={e => setProductAmount(productAmount + 1)}>+</span></button>
                    </div>

                    <div className="btn__box"><button type="button"
                        onClick={e => addToShoppingcart()} className="btn__two"><FormattedMessage id="add-to-cart" /></button>
                    </div>
                </div>
            </>)
            }

            <ProductGotoShoppingcartModal
                modalIsOpen={modalIsOpen}
                modalIsOpenHandler={modalIsOpenHandler}
                history={history}
            />
        </div>
    );
};

export default ProductWebshopSideBarView;