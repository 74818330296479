import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, } from 'antd';
import { FormattedMessage } from 'react-intl';
import SignatureCanvas from 'react-signature-canvas';
import { LoadingOutlined, } from '@ant-design/icons';
import { Eraser, } from "@phosphor-icons/react";

const SignaturePad = ({
    setSignature,
    putSignature = undefined,

}) => {

    const [canvasProps, setCanvasProps] = useState({});
    const [canvasLoading, setCanvasLoading] = useState(true);
    const sigCanvas = useRef({})
    const ref = useRef(null);       // needed to determin the canvas size
    const canvasMargin = 30;

    const clearCanvas = () => {
        sigCanvas.current.clear();
        setSignature(undefined);
    };

    useEffect(() => {
        setTimeout(() => {
            const size = getCanvasSize();
            setCanvasProps({ width: size - canvasMargin, height: 300, className: "signature-canvas" });
            setCanvasLoading(false);

        }, 100);

        function handleResize() {
            const size = getCanvasSize();
            setCanvasProps({ width: size - canvasMargin, height: 300, className: "signature-canvas" });
        };

        // adding the resize event listner
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []);

    // TODO: Try to write back the signature
    // useEffect(() => {
    //
    //     if (putSignature) {
    //         if (typeof sigCanvas.current.fromDataURL === "function") {
    //
    //             sigCanvas.current.fromDataURL(putSignature);
    //         }
    //     }
    // }, [sigCanvas]);

    // getting the size of the canvas container.
    const getCanvasSize = () => {
        const element = ref.current;
        const size = element.querySelector('div.canvas-container').offsetWidth;
        return size
    };

    const getSignature = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

        // save the information
        setSignature({ signature: signature, })
    };

    // function getWindowDimensions() {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return { width, height };
    // }

    return (

        <Row ref={ref}>
            <Col span={24} className="text-center canvas-container" >
                {canvasLoading ? (<LoadingOutlined />) : (
                    <>
                        <SignatureCanvas
                            fromDataURL={putSignature}
                            onEnd={getSignature}
                            dotSize={1} penColor='#000'
                            ref={sigCanvas} canvasProps={canvasProps} />

                        <div className="d-xsl-none">
                            <div className="">
                                <Button
                                    size="small"
                                    onClick={clearCanvas}
                                    className="clear-canvas"><Eraser size={14} weight="light" className="mr-1" /><FormattedMessage id="clear" />
                                </Button>
                            </div>
                        </div>
                    </>

                )}
            </Col>
        </Row>
    );

};

export default SignaturePad;