import React, { useState, useEffect, } from "react";
import { GetLocations } from '../../../services/products'
import { CheckCircle, } from "@phosphor-icons/react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "antd";

const ProductLocationsView = ({
    data = [],
    history,
    ...props
}) => {

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            getLocations();
        }
        // eslint-disable-next-line
    }, [data]);


    const getLocations = () => {
        GetLocations(history).then(response => {
            if (response.status) {
                const tmp_data = response.data.locations;
                const matchingLocations = tmp_data.filter(location => data.includes(location.location_id));
                setLocations(matchingLocations);
            }
        })
    };

    return (

        <div className="">
            <div className="data__list">
                <span className="heading-divider mx-auto mb-7" />
                <h5 className="mb-2">Onze locaties</h5>
                {locations.length > 0 ? (
                    <p>Op onderstaande locaties kan je dit product ophalen of inleveren.</p>
                ) : (
                    <p>Dit product kan niet worden opgehaald, informeer naar de mogelijkheden.</p>
                )}
            </div>

            <div className="mt-4">
                <ul className="">
                    {locations.map((item, index) => (
                        <React.Fragment key={index}>
                            <li className="mb-4" >
                                <p><strong><CheckCircle size={20} className="mr-1" /> {item.location_name}</strong></p>
                                <small>{item.location_note}</small>
                                {item?.location_show_opening_hours && (<>
                                    <p><FormattedMessage id="openings-hours" /></p>
                                    <Row>
                                        {item?.location_hours?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Col span={6}><small><FormattedMessage id={item.label} />:</small></Col>
                                                <Col span={18}>{item.enable ? <small>{item.open} - {item.close}</small> : <p><FormattedMessage id="closed" /></p>}</Col>
                                            </React.Fragment>
                                        ))}
                                    </Row>
                                </>)}
                            </li>


                        </React.Fragment>
                    ))}

                </ul>

            </div>
        </div>

    );
};

export default ProductLocationsView;