
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { UserStatus } from '../users';
import { useIntl } from "react-intl";
import { message } from 'antd';

export const useFetchAuth = ({
    required,
    location,
}) => {

    let history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState();
    const intl = useIntl();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!loading && auth) { return; };
        let isMounted = true;

        // Only set the user if the component is still mounted
        if (isMounted) {
            // When the user is not logged in but login is required
            if (!auth) {

                UserStatus({ location: location })
                    .then(response => {
                        if (response.status && response?.data.authenticated && response?.data.permission !== "administrators") {
                            setLoading(false);
                            setAuth(response.data);
                        } else {
                            if (required) {
                                if (pathname !== "/") {
                                    message.warning(intl.formatMessage({ id: "SESSION_EXPIRED" }));
                                    history("/");
                                }
                            }
                            setLoading(false);
                            return false;
                        };
                    })
                    .catch(error => {
                        if (required) {
                            if (error?.status === 403) {
                                if (pathname !== "/") {
                                    message.warning(intl.formatMessage({ id: "SESSION_EXPIRED" }));
                                }
                            }
                            history('/');
                        }
                        setLoading(false);
                    });
            } else {
                // authorisation is not needed, remove loading
                setLoading(false);
                return;
            }
        } else {
            setLoading(false);
        }

        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { auth, loading };
};
