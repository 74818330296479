import React, { useEffect, useState, } from "react";
import { Link, } from 'react-router-dom';
import { FormattedMessage, } from "react-intl";
import { Badge, Dropdown, Space, Menu, Drawer, } from 'antd';
import { House, SquaresFour, AddressBook, User, ShoppingCart, X, CaretDown, Storefront, } from '@phosphor-icons/react';

import { GetCategories, } from '../../services/products';
import { AccountLoginModal } from '../../components/account.modals';

import MobileMenuIcon from '../../assets/images/mobilemenu.svg';
import NLFlag from '../../assets/images/flags/nl.png';
import ENFlag from '../../assets/images/flags/en.png';
import DEFlag from '../../assets/images/flags/de.png';

const Logo = require(`./../../assets/images/${process.env.REACT_APP_LOGO}`);

const HomeHeaderBar = ({
    history,
    counterShoppingcart = 0,
    copyCategories = () => { },
    setAuth = () => { },
    ...props
}) => {


    const [openMenu, setOpenMenu] = useState(false);
    const { branding_logo, branding_color_primary, auth, language, setCurrentLanguage, } = props;
    const { permission_template: { product_sell } = {} } = props;
    const [openLoginModal, setOpenLoginModal] = useState(false);

    useEffect(() => {
        getCategories();
        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAuth(auth);

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const getCategories = () => {
        GetCategories(history).then(response => {
            if (response.status) {
                // setCategories(response.data.categories);
                copyCategories(response.data.categories);
            }
        });
    };

    const checkLoginStatus = () => {
        if (auth) {
            history('/dashboard');
        } else {
            setOpenLoginModal(true);
        }
    };

    // Language items
    const items = [
        {
            label: <><img className="language-flag-item" alt="Language EN" src={ENFlag} /> EN</>,
            key: 'en',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language NL" src={NLFlag} /> NL</>,
            key: 'nl',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language DE" src={DEFlag} /> DE</>,
            key: 'de',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
    ];

    const mobileMenu = [
        // {
        //     key: 1,
        //     icon: <i className="flaticon-user"></i>,
        //     label: "Inloggen",
        //     className: "text-heading lh-1 text-capitalize active py-2",
        //     // onClick: () => { history("/dashboard") }
        //     onClick: e => checkLoginStatus()
        // },
        // { type: 'divider' },

        {
            key: 4,
            icon: <House size={16} weight="thin" />,
            label: <FormattedMessage id="home" />,
            className: "text-heading lh-1 text-capitalize py-2",
            onClick: () => { history("/") },
        },
        { type: 'divider' },
        {
            key: 6,
            icon: <SquaresFour size={16} weight="thin" />,
            label: <FormattedMessage id="category" />,
            className: "text-heading lh-1 text-capitalize py-2",
            onClick: () => { history("/category"); setOpenMenu(false); },
        },
        product_sell &&
        {
            key: "webshop",
            icon: <Storefront size={16} weight="thin" />,
            label: <FormattedMessage id="webshop" />,
            className: "text-heading lh-1 text-capitalize py-2",
            onClick: () => { history("/webshop"); setOpenMenu(false); },
        },
        { type: 'divider' },
        {
            key: 8,
            icon: <AddressBook size={16} weight="thin" />,
            label: <FormattedMessage id="contact" />,
            className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
            onClick: () => { history("/#contact"); setOpenMenu(false); },
        },
        { type: 'divider' },
        {
            key: 'cart',
            icon: <ShoppingCart size={16} weight="thin" />,
            label: <Badge count={counterShoppingcart} className="" colorrr={branding_color_primary}><FormattedMessage id="shoppingcart" />    </Badge>,
            className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
            onClick: () => { history("/shoppingcart") }
        },
        { type: 'divider' },
        {
            key: 'lang',
            label: <Dropdown
                menu={{ items, }}>
                <div>
                    <Space className="text-uppercase">
                        {language}
                        <CaretDown size={16} className="mr-1" />
                    </Space>
                </div>
            </Dropdown>,
            className: "text-heading lh-1 text-capitalize active py-2",
            // onClick: () => { history("/dashboard") }
        },

    ].filter(Boolean);

    return (

        <React.Fragment>
            {/* <!-- main header --> */}
            <header className="main-header">

                {/* <!-- header-lower --> */}
                <div className="header-lower">
                    <div className="header_bottom p_relative">
                        <div className="logo__shape"></div>
                        <div className="auto_container">
                            <div className="outer-box">
                                <div className="logo-box">
                                    <figure className="logo"><a href="/"><img src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} alt="" /></a></figure>
                                </div>
                                <div className="menu-area">

                                    {/* <!--Mobile Navigation Toggler--> */}
                                    <div className="user pointer" onClick={e => checkLoginStatus()}>
                                        <div><User /></div>
                                    </div>

                                    <div className="mobile-nav-toggler"
                                        onClick={e => { setOpenMenu(!openMenu) }}>
                                        <img src={MobileMenuIcon} alt="" />
                                    </div>

                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                {/* <li className="dropdown"><a href="index.html">Home <HomeOutlined /> </a>
                                                    <ul>
                                                        <li><a href="index.html">Home One</a></li>
                                                    </ul>
                                                </li> */}
                                                <li><Link to="/"><House size={22} weight="thin" /> Home</Link></li>
                                                <li><Link to="/category"><SquaresFour size={22} weight="thin" /> <FormattedMessage id="category" /></Link></li>
                                                {product_sell && (
                                                    <li><Link to="/webshop"><Storefront size={22} weight="thin" /> <FormattedMessage id="webshop" /></Link></li>
                                                )}
                                                <li><a href="/#contact"><AddressBook size={22} weight="thin" /> <FormattedMessage id="contact" /></a></li>
                                                {/* <li className="dropdown"><a href="product.html">Product</a>
                                                    <ul>
                                                        <li><a href="product.html">Product</a></li>
                                                        <li><a href="product-details.html">Product Details</a></li>
                                                        <li><a href="cart.html">Cart Page</a></li>
                                                        <li><a href="checkout.html">Checkout Page</a></li>
                                                        <li><a href="success.html">Success Page</a></li>
                                                        <li><a href="login.html">Login Page</a></li>
                                                    </ul>
                                                </li> */}
                                                {/* <li><a href="blog.html">Blog</a></li> */}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                <div className="header__right">
                                    <div className="icon__list">
                                        <ul>
                                            {/* <li> <a href="!#"><i className="flaticon-search-interface-symbol"></i></a></li> */}
                                            <li><div className="pointer" onClick={e => checkLoginStatus()}><User size={22} weight="thin" /></div> </li>
                                            {/* <li> <a href="!#"><i className="flaticon-heart"></i></a></li> */}
                                            <li>
                                                {/* <Badge count={counterShoppingcart} className="" color={branding_color_primary}>
                                                    <div className="single-header-right sidenav-btn-box">
                                                        <div className="side-nav-open side-nav-opener">
                                                            <i className="flaticon-shopping-cart"></i>
                                                        </div>
                                                    </div>
                                                </Badge> */}

                                                <Badge count={counterShoppingcart} className="" colorrr={branding_color_primary}>
                                                    <div className="pointer" onClick={e => history("/shoppingcart")}>
                                                        <ShoppingCart size={22} weight="thin" />
                                                    </div>
                                                </Badge>

                                            </li>
                                            <li>
                                                <Dropdown
                                                    menu={{ items, }}>
                                                    <div>
                                                        <Space className="text-uppercase">
                                                            {language}
                                                            <CaretDown size={16} className="mr-1" />
                                                        </Space>
                                                    </div>
                                                </Dropdown>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--sticky Header--> */}
                <div className="sticky-header">
                    <div className="auto_container">
                        <div className="outer-box">
                            <div className="logo-box">
                                <figure className="logo"><a href="index.html"><img src={Logo} alt="" /></a></figure>
                            </div>
                            <div className="menu-area">

                                <div className="mobile-nav-toggler">
                                    <img src={MobileMenuIcon} alt="" />
                                </div>

                                <nav className="main-menu clearfix">

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!-- Mobile Menu  --> */}
            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                <div className="close-btn"><i className="fas fa-times"></i></div>
                <nav className="menu-box">
                    <div className="nav-logo"><a href="/"><img src={Logo} alt="" title="" /></a></div>
                    <div className="menu-outer">

                        <Drawer
                            title={<figure className="logo" style={{ width: 100 }}><a href="/"><img src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} alt="" /></a></figure>}
                            placement="left"
                            closeIcon={false}
                            onClose={e => setOpenMenu(false)}
                            open={openMenu}
                            key="left"
                            width="75%"
                            extra={[
                                <span
                                    key="close"
                                    onClick={e => setOpenMenu(false)}>
                                    <X size={16} className="mr-1" />
                                </span>
                            ]}>

                            <Menu
                                className="mt-4"
                                style={{ width: "100%" }}
                                items={mobileMenu}
                            />
                        </Drawer>

                    </div>
                </nav>
            </div>
            {/* <!-- End Mobile Menu --> */}

            {openLoginModal && (
                <AccountLoginModal
                    history={history}
                    modalIsOpen={openLoginModal}
                    modalIsOpenHandler={setOpenLoginModal}
                    {...props}
                />
            )}

        </React.Fragment>
    );
};


export default HomeHeaderBar;