import React from "react";
import { Skeleton, } from 'antd';

const ProductCardLoading = ({
    ...props
}) => {

    return (

        <div className="shop__content d-block">
            <div className="shop__top">
                <div className="inner__box">
                    <figure className="image__box">
                        <Skeleton.Image
                            active={true}
                            style={{ width: "300px", height: "300px" }} />
                    </figure>
                </div>
            </div>

            <div className="shop__bottom">
                <div className="product__content">
                    <h5><Skeleton.Input active={true} /></h5>
                </div>
                <div className=" ">
                    <h5><Skeleton.Button active={true} /></h5>
                </div>
            </div>
        </div>

    );
};


export default ProductCardLoading;