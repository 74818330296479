import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation, } from "react-router-dom";

import Spinner from './../Spinner';
import { CustomCSS } from "../helpers";
import { ApplicationStatus } from '../../services/public';
import NotAuthorized from "../not.authorized";
import ErrorPage from '../error';
import LoginPage from './login.page';
import { useFetchAuth } from '../../services/authentication/auth';

const PublicLayout = ({
    children,
    className = "",
    ...props
}) => {

    const { setThemeData, GApageView, } = props;
    const [loadingData, setLoadingData] = useState(true);
    const [authorized, setAuthorized] = useState(false);
    const [licenseData, setLicenseData] = useState(false);
    const [connectError, setConnectError] = useState(false);
    const [appData, setAppData] = useState({});
    const { auth } = useFetchAuth({ required: false });
    GApageView(window.location.pathname);
    const { pathname } = useLocation();

    useEffect(() => {
        getApplicationStatus();
        // eslint-disable-next-line
    }, [auth]);

    // activate google analytics
    useEffect(() => {
        if (appData.plugin_data) {
            for (let index = 0; index < appData.plugin_data.length; index++) {
                const element = appData.plugin_data[index];
                if (element.plugin_key === "google_analytics") {
                    ReactGA.initialize(element.ga_property_id);
                    break;
                }
            }
        }

        // eslint-disable-next-line
    }, [appData]);

    const getApplicationStatus = () => {
        ApplicationStatus()
            .then(response => {
                setAppData(response.data, setLoadingData(false));
                setThemeData(response.data);
                setAuthorized(response.status);
                setLicenseData(response.msg);
                setConnectError(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error?.status !== 403) {
                    setConnectError(true);
                    setLoadingData(false);
                }
            })
    };

    if (loadingData) {
        return (<Spinner />);
    } else {
        return (
            <div className="boxed_wrapper">
                <CustomCSS {...props} />
                {authorized && !connectError ? (
                    <React.Fragment>

                        {appData.branding_disable_shop && pathname.indexOf('/payment') ? (
                            <LoginPage
                                {...props}>webshop is disabled &#128075;</LoginPage>
                        ) : (
                            <React.Fragment>
                                {React.Children.map(children, (child) => {
                                    return React.cloneElement(child, { ...appData, auth: auth });
                                })}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <>
                        {connectError ? (
                            <ErrorPage refresh={getApplicationStatus}{...props} />
                        ) : (
                            <NotAuthorized msg={licenseData} {...props} />
                        )}
                    </>
                )
                }
            </div>
        );
    }
};

export default PublicLayout;