import React from "react";
import { Col, Row, Dropdown, Space, notification, } from 'antd';
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate, } from 'react-router-dom';

import { ProfileImage } from '../../components/helpers';
import { UserSignout } from '../../services/users';
import { ShieldCheck, SignOut, UserGear, CaretDown, } from "@phosphor-icons/react";

import NLFlag from '../../assets/images/flags/nl.png';
import ENFlag from '../../assets/images/flags/en.png';
import DEFlag from '../../assets/images/flags/de.png';


const HeaderBar = ({
    auth,
    collapsed,
    setCollapsed,
    ...props
}) => {

    const { email } = auth;
    const intl = useIntl();
    const history = useNavigate();
    const { language, setCurrentLanguage } = props;
    const localdata = JSON.parse(localStorage.getItem('authentication'));
    const { profile_image, } = localdata || {};

    const languageItems = [
        {
            label: <><img className="language-flag-item" alt="Language EN" src={ENFlag} /> EN</>,
            key: 'en',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language NL" src={NLFlag} /> NL</>,
            key: 'nl',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language DE" src={DEFlag} /> DE</>,
            key: 'de',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
    ];

    const signout = () => {
        const { username, access_token } = auth;

        UserSignout({ username: username, access_token: access_token }).then(response => {
            if (response.status) {
                history('/');

                notification['success']({
                    message: 'Uitloggen',
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        });
    };


    const items = [
        // {
        //     label: <div onClick={e => toggleLanguage()}><Translate size={18} weight="light" /> <FormattedMessage id="toggle-language" />: {displayLanguageItem?.toUpperCase()}</div>,
        //     key: "toggleLang",
        // },
        {
            label: <Link to="/profile" className="dropdown-itemm">
                <UserGear size={18} weight="light" /> <FormattedMessage id="profile-settings" />
            </Link>,
            key: 1,
        },
        {
            label: <Link to="/profile/security" className="dropdown-itemm">
                <ShieldCheck size={18} weight="light" /> <FormattedMessage id="profile-security" />
            </Link>,
            key: 2,
        },
        { type: 'divider', },
        {
            label: <><SignOut size={18} weight="light" /> <FormattedMessage id="profile-signout" /></>,
            key: 3,
            onClick: e => { signout(e) }
        },
    ];


    return (

        <Row>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>

                {/* <Dropdown menu={{ items: quickAddMenu }}
                    className="ml-2">
                    <Button size="large" type="primary" ghost><PlusSquareOutlined className="mr-1" /> Toevoegen</Button>
                </Dropdown> */}

                {/* <BellOutlined /> Informatie of een meldingen voor je bezoekers. */}
                {/* <form>
                            <div className="input-group">
                                <div className="input-group-prepend mr-0">
                                    <button className="btn border-0 shadow-none fs-20 text-muted p-0" type="submit">
                                        <i className="far fa-search"></i></button>
                                </div>
                                <input type="text" className="form-control border-0 bg-transparent shadow-none" placeholder="Search for..." name="search" />
                            </div>
                        </form> */}
            </Col>

            <Col flex="auto">
                {/* Profile dropdown menu */}

                <div className="float-end">
                    <Space>
                        <div>
                            <Dropdown
                                menu={{ items: languageItems }}>
                                <div>
                                    <Space className="text-uppercase">
                                        {language}
                                        <CaretDown size={16} className="mr-1" />
                                    </Space>
                                </div>
                            </Dropdown>
                        </div>

                        <Dropdown
                            menu={{ items }}
                            trigger={['click']}
                            className="pointer">
                            <span onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <div className="fs-13 font-weight-500 lh-1">
                                        &nbsp;
                                    </div>
                                    <div className="mr-1">
                                        <ProfileImage
                                            email={email}
                                            image={profile_image ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${profile_image}` : undefined}
                                        />
                                    </div>
                                </Space>
                            </span>
                        </Dropdown>
                    </Space>
                </div>

            </Col>
        </Row>

    );
};

export default HeaderBar;