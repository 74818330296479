import React from "react";
import { FormatEuro, } from '../../../components/helpers';
import truncate from '../../../components/helpers/truncate.html';
import ProductPhotoView from '../detail.views/product.photo.view';
import ProductWebshopSideBarView from './product.webshop.side.bar';
import { useLocation, Link, } from 'react-router-dom';
import { FormattedMessage, } from "react-intl";

const ProductDetailWebshopPage = ({
    productData,
    selectedCategory,
    options,
    history,
    ...props

}) => {

    const { state } = useLocation();
    const { language } = props;

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (

        <section className="product__details">
            <div className="auto_container">
                <div className="product__details__content">

                    {/* Bread crums */}
                    <div className="bread__cum">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>/</li>
                            <li><Link to="/webshop" state={{ cat_id: productData?.product_category, date_range: state?.date_range }}><FormattedMessage id="products" /></Link></li>
                            <li>/</li>
                            <li>{productData?.product_names?.[`product_name_${language}`] || productData?.product_names?.product_name_nl || productData?.product_name}</li>
                        </ul>
                    </div>

                    {/*  PRoduct details container */}
                    <div className="row clearfix">

                        {/* Photo cointainer left */}
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <ProductPhotoView data={productData?.product_images || []} />
                        </div>

                        {/* Details container right */}
                        <div className="col-lg-6 col-md-12 col-sm-12 content__column">
                            <React.Fragment>
                                {productData && (
                                    <div className="product__info">
                                        <h2>{productData.product_names?.[`product_name_${language}`] || productData.product_names?.product_name_nl || productData.product_name}</h2>
                                        <div className="price__item">

                                            {/* Price details for shop products */}
                                            <span className="item__price"><FormatEuro amount={productData.product_pricing.product_pricing[0].price} /></span>
                                        </div>

                                        {/* <div className="customer__review ">
                        <ul className="rating__box ">
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                        <div className="reviews"><span>(5 Reviews)</span></div>
                    </div>

                    <div className="pepole__view">
                        <li><i className="flaticon-view"></i> 21 people are viewing this right now</li>
                    </div> */}

                                        <div className="product__color mt-4" style={{ minHeight: "150px" }}>
                                            {renderHTMLContent(truncate(productData.product_descriptions?.[`product_description_${language}`] || productData.product_descriptions?.product_description_nl || productData.product_description, 150))}

                                            {/* <div className="color__title">
                            <h6>Beschikbare opties</h6>
                        </div>
                        <ul>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                        </ul> */}
                                        </div>

                                        <div className="product-reservation-container">
                                            <ProductWebshopSideBarView
                                                options={options}
                                                data={productData}
                                                history={history}
                                                selectedCategory={selectedCategory}
                                                {...props} />
                                        </div>


                                        {/* <ul className="share__option">
                        <li><i className="flaticon-question-mark"></i> ASK ABOUT THIS PRODUCT</li>
                        <li><i className="flaticon-share"></i> SHARE</li>
                    </ul> */}

                                        <div className="other__links">
                                            {/* <ul className="list__link">
                            <li><span>Vendor:</span>Honda</li>
                            <li><span>Availability:</span> In Stock</li>
                            <li><span>SKU:</span> {data.product_sku}</li>
                            <li><span>Collection:</span>All</li>
                        </ul> */}
                                        </div>

                                    </div>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ProductDetailWebshopPage;