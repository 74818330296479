import React, { useEffect, useState, } from "react";
import { List, notification, Form, DatePicker, } from 'antd';
import { useLocation, } from 'react-router-dom';
import { FormattedMessage, useIntl, } from "react-intl";
import { FormatEuro, calculateTotal, optionPricing, getRentalPeriod, } from '../../../components/helpers';
import ProductGotoShoppingcartModal from '../detail.views/product.goto.shoppingcart.modal';
import { GetProductAvailability, } from '../../../services/products';
import ProductOptionListCard from '../views/product.option.list.card';
import ProductSideBarDateRangeView from './product.side.bar.date.range';
import ProductSideBarTimeSlotView from './product.side.bar.time.slot';
import Moment from 'react-moment';
import 'moment-timezone';
import setHours from 'date-fns/setHours';
import antdLocale from 'antd/es/date-picker/locale/nl_NL';

const ProductSideBarView = ({
    data,
    selectedCategory,
    options,
    history,
    ...props
}) => {

    const [modalIsOpen, modalIsOpenHandler] = useState(false);
    const [availableChecked, setAvailableChecked] = useState(false);
    const [available, setAvailable] = useState(false);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedTime, setSelectedTime] = useState();         // start_time (used for hourly rental period)
    const [intervalAmount, setIntervalAmount] = useState(1);    // set the interval amount
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [productAmount, setProductAmount] = useState(data.product_min_order_quantity);
    const [productTotalPrice, setProductTotalPrice] = useState(0);
    const [calculatedProductTotals, setCalculatedProductTotals] = useState({});
    const [timeslotRentalPeriod, setTimeslotRentalPeriod] = useState({}); // data.product_pricing.product_time_slots

    const { triggerShoppingCart, themeData, language, } = props;
    const { branding_location, branding_financial, } = themeData;
    const { state } = useLocation();
    const intl = useIntl();

    // setup the search field and get the options after state has changed.
    useEffect(() => {
        if (state?.date_range?.length > 0) {
            const startDateJS = new Date(state?.date_range[0]);
            const endDateJS = new Date(state?.date_range[1]);
            setSelectedDate([startDateJS, endDateJS]);
            getTimeslotRentalPeriod();
            // checkAvailability();
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (timeslotRentalPeriod) {
            if (Object.keys(timeslotRentalPeriod).length > 0 && timeslotRentalPeriod.ts_interval_type !== "WEEK") {
                setIntervalAmount(timeslotRentalPeriod.ts_interval_amount || 1);
            }
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeslotRentalPeriod]);

    useEffect(() => {
        if (timeslotRentalPeriod.ts_interval_type === "DAY" && selectedDate.length === 2) { checkAvailability() }
        if (timeslotRentalPeriod.ts_interval_type === "HOUR" && selectedTime && selectedDate.length === 2) { checkAvailability() }
        if (timeslotRentalPeriod.ts_interval_type === "WEEK" && intervalAmount > 0 && selectedDate.length === 2) { checkAvailability() }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedTime, timeslotRentalPeriod, productAmount, intervalAmount]);

    useEffect(() => {
        if (productAmount < 1) { setProductAmount(1) }

        // also set the mandatory options amount after the productAmount has changed
        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (element.product_pricing.option_mandatory) {
                setOptionSelection(true, element);
            }
        }

        // eslint-disable-next-line
    }, [productAmount]);

    // control the interval Amount and change selectedDate based on the intervalAmount.
    useEffect(() => {
        if (intervalAmount < 1) { setIntervalAmount(1) }
        if (selectedDate.length === 2) {
            const startDate = selectedDate[0];
            const startDateJS = new Date(startDate);
            const endDateJS = new Date(startDateJS);
            endDateJS.setDate(startDateJS.getDate() + (timeslotRentalPeriod?.ts_enable_night_count ? intervalAmount * 7 : intervalAmount * 6));
            setSelectedDate([startDateJS, endDateJS]);
        }
        // eslint-disable-next-line
    }, [intervalAmount]);

    // trigger calculateTotal
    useEffect(() => {
        const daysFreeOfCharge = branding_location?.location_hours.filter(day => !day.enable && !day.charge_when_closed);

        const record = {
            selected_options: selectedOptions,
            possible_options: options,
            date_range: selectedDate,
            days_free_of_charge: daysFreeOfCharge,
            bookdetail_amount: productAmount,
            product_data: data,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
            interval_amount: intervalAmount,
        };

        if (timeslotRentalPeriod?.ts_interval_type === "HOUR") {
            var interval_price = timeslotRentalPeriod?.ts_interval_pricing[0]?.interval_price;
            // setPricePerInterval(interval_price);
            setProductTotalPrice(productAmount * interval_price);
        } else {
            const results = calculateTotal(record);
            setCalculatedProductTotals(results);
            // setPricePerInterval(results.price_per_interval);
            setProductTotalPrice(results.total_price);
        }

        // eslint-disable-next-line
    }, [selectedDate, productAmount, selectedOptions, selectedCategory, timeslotRentalPeriod, intervalAmount]);

    // set the mandatory options after the date has been selected
    useEffect(() => {
        if (options) {
            for (let index = 0; index < options.length; index++) {
                const element = options[index];
                if (element.product_pricing.option_mandatory) {
                    setOptionSelection(true, element);
                }
            }
        }
        // eslint-disable-next-line
    }, [selectedDate, options]);

    useEffect(() => {
        if (selectedDate.length === 2) {
            getTimeslotRentalPeriod();
        }

        // eslint-disable-next-line
    }, [selectedDate, selectedTime]);

    const getTimeslotRentalPeriod = () => {
        const tmp = getRentalPeriod(selectedDate, data.product_pricing.product_time_slots);
        if (tmp) {
            setTimeslotRentalPeriod(tmp);
        } else {
            notification['error']({
                message: intl.formatMessage({ id: "PERIOD_NOT_INSIDE_TIMESLOT" }),
                description: intl.formatMessage({ id: "select-period-not-available" }),
            });
        }
    };

    const checkAvailability = () => {
        setAvailableChecked(false);

        const post_data = {
            date_range: selectedDate,
            product_id: data.product_id,
            start_time: selectedTime,
            interval_amount: intervalAmount,
            interval_type: timeslotRentalPeriod.ts_interval_type,
        };

        // if the requested amount is large then the stock amount, block
        GetProductAvailability(post_data, history).then(response => {
            if (response.status) {
                const products_available = data.product_stock_amount - response.data.reserved;
                if (products_available >= productAmount) {
                    setAvailable(true);
                } else {
                    setAvailable(false);
                }
                setAvailableChecked(true);
            } else {
                notification['error']({
                    message: "Error checking products",
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        })
    };

    const setOptionSelection = (status, item) => {
        const daysFreeOfCharge = branding_location?.location_hours.filter(day => !day.enable && !day.charge_when_closed);

        // Check if currect option is already in array selectedOptions
        // const existing_option = selectedOptions.find((option) => { return option.product_id === item.product_id }) || [];
        const option_index = selectedOptions.findIndex((option) => option.product_id === item.product_id);

        const record = {
            date_range: [new Date(selectedDate[0]), new Date(selectedDate[1])],
            days_free_of_charge: daysFreeOfCharge,
            bookdetail_amount: productAmount,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
            option_data: item,
            parent_product_data: data,
        };
        // if selectedDate is not set, do not add the option to the selectedOptions (this will trigger if mandatory options has been set)
        if (selectedDate) {
            const results = optionPricing(record);
            const new_item = {
                label: item.product_names?.[`product_name_${language}`] || item.product_name,
                price: results.price_per_interval,
                product_one_time_item: item.product_pricing.product_one_time_item,
                enable: status,
                product_id: item.product_id,
                product_vat_group: item?.product_pricing?.product_vat_group,
                bookdetail_amount: item.product_pricing.option_mandatory ? productAmount : 1,
                option_data: item,
            };

            let newState = [...selectedOptions];

            // existing_option
            if (option_index === -1) {
                newState.push(new_item);
            } else {
                newState[option_index] = new_item;
            }

            setSelectedOptions(newState);
        }
    };

    const addToShoppingcart = () => {

        if (selectedDate.length !== 2) {
            return false;
        }

        if (productAmount < data.product_min_order_quantity) {
            notification['warning']({
                message: intl.formatMessage({ id: "order-to-small" }),
                description: intl.formatMessage({ id: "order-to-small-info" }),
            });
            return false;
        }

        const daysFreeOfCharge = branding_location?.location_hours.filter(day => !day.enable && !day.charge_when_closed);
        const shoppingcart = {
            product_data: data,
            product_category: selectedCategory,
            bookdetail_amount: productAmount,
            date_range: selectedDate,
            days_free_of_charge: daysFreeOfCharge,
            possible_options: options,
            selected_options: selectedOptions,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
            start_time: selectedTime,
            interval_amount: intervalAmount, // timeslotRentalPeriod.ts_interval_amount,
            interval_type: timeslotRentalPeriod.ts_interval_type,
        };

        triggerShoppingCart(shoppingcart);
        modalIsOpenHandler(true);
    };

    const setStartDateTime = () => {
        if (selectedTime) {
            const [hour, minute] = selectedTime.split(":").map(Number);
            const newDate = new Date(selectedDate[0]);
            newDate.setHours(hour, minute);
            return newDate;
        }
    };

    const dateWeekSelector = (dates, dateStrings) => {
        const startDateJS = dates.toDate();
        const endDateJS = new Date(startDateJS);
        endDateJS.setDate(startDateJS.getDate() + (timeslotRentalPeriod?.ts_enable_night_count ? intervalAmount * 7 : intervalAmount * 6));
        setSelectedDate([startDateJS, endDateJS]);
    };

    return (

        <div className="">

            <h4 className="hover-primary my-2 text-center">
                <FormattedMessage id="book-now" /> {data.product_names?.[`product_name_${language}`] || data.product_names?.product_name_nl || data.product_name}</h4>

            {/* Switch between date range and timeslot */}
            {(timeslotRentalPeriod?.ts_interval_type === "HOUR" || data.product_pricing.product_time_slots[0].ts_interval_type === "HOUR") && (
                <>
                    <ProductSideBarTimeSlotView
                        history={history}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        timeslots={data?.product_pricing?.product_time_slots}
                        timeslotRentalPeriod={timeslotRentalPeriod}
                        {...props}
                    />
                </>
            )}

            {(timeslotRentalPeriod?.ts_interval_type === "DAY" || data.product_pricing.product_time_slots[0].ts_interval_type === "DAY") && (
                <div className="form-group mb-2 mt-2">
                    <ProductSideBarDateRangeView
                        history={history}
                        // totalDays={totalDays}
                        timeslotRentalPeriod={timeslotRentalPeriod}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        {...props} />
                </div>
            )}

            {(timeslotRentalPeriod?.ts_interval_type === "WEEK" || data.product_pricing.product_time_slots[0].ts_interval_type === "WEEK") && (
                <div className="form-group mb-2 mt-2">
                    <Form.Item label={<FormattedMessage id="choose-start-date" />}>
                        <DatePicker
                            size="large"
                            style={{ width: "100%" }}
                            locale={antdLocale}
                            // selectedDate={selectedDate}
                            onChange={dateWeekSelector}
                            format="DD-MM-YYYY"
                        />
                    </Form.Item>

                    <Form.Item label={<FormattedMessage id="choose-amount-of-weeks" />}>
                        <div className="item__quantity small">
                            {/* Amount of weeks */}
                            <button type="button"><span onClick={e => setIntervalAmount(intervalAmount - 1)}>-</span>
                                {intervalAmount}
                                <span onClick={e => setIntervalAmount(intervalAmount + 1)}>+</span></button>
                        </div>

                        {/* <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            // locale={antdLocale}
                            // selectedDate={selectedDate}
                            // onChange={dateWeekSelector}
                            format="DD-MM-YYYY"
                        /> */}
                    </Form.Item>

                </div>
            )}

            {/* Show options  */}
            {options.length > 0 && (
                <>
                    <hr />
                    <h5 className="mb-2"><FormattedMessage id="optional-on-this-product" /></h5>

                    <List
                        grid={{
                            gutter: 16, xs: 1,
                            sm: 1, md: 1, lg: 2,
                            xl: 2, xxl: 2,
                        }}
                        itemLayout="horizontal"
                        className="mt-4"
                        dataSource={options}
                        rowKey={item => item?.product_id}
                        renderItem={(item, index) => (
                            <List.Item
                                key={index}>
                                <ProductOptionListCard
                                    readOnly={false}
                                    data={item}
                                    setOptionSelection={setOptionSelection}
                                    selectedOptions={selectedOptions}
                                    available={available}
                                    availableChecked={availableChecked}
                                    selectedDate={selectedDate}
                                    {...props} />
                            </List.Item>
                        )}
                    />
                </>
            )}

            {selectedDate.length > 0 && timeslotRentalPeriod !== undefined && (
                <div className=" mb-2">
                    {timeslotRentalPeriod.ts_interval_type === "DAY" && (
                        <React.Fragment>
                            {/* <h6><FormattedMessage id="amount-days" />: {totalDays} <small>(<FormattedMessage id="price-per-day" />: <FormatEuro amount={pricePerInterval} />)</small></h6> */}
                            <div><FormattedMessage id="pick-up" />: <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY" date={selectedDate[0]} /></div>
                            <div><FormattedMessage id="return" />: <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY" date={selectedDate[1]} /></div>
                        </React.Fragment>
                    )}

                    {timeslotRentalPeriod.ts_interval_type === "HOUR" && (
                        <React.Fragment>
                            <div><FormattedMessage id="pick-up" />: <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY - HH:mm" date={setStartDateTime()} /></div>
                            <div><FormattedMessage id="return" />: <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY - HH:mm" date={setHours(setStartDateTime(), new Date(setStartDateTime()).getHours() + (timeslotRentalPeriod.ts_interval_amount))} /></div>
                        </React.Fragment>
                    )}
                </div>
            )}

            <div className=" mb-2 text-center">
                <h4><FormattedMessage id="total" /> <FormatEuro amount={productTotalPrice} /></h4>
            </div>

            <div className="text-center">
                {selectedDate.length === 0 && (
                    <div style={{ color: "red" }}><small><FormattedMessage id="first-choose-date" />,<br /><FormattedMessage id="checking-availabilty" /></small></div>
                )}

                {selectedDate.length === 2 && availableChecked && !available && (
                    <div style={{ color: "red" }}>{data.product_names?.[`product_name_${language}`] || data.product_name} <FormattedMessage id="is-not-available-in-period" /></div>
                )}

                {data.product_min_order_quantity > 1 && (
                    <div style={{ color: "red" }} className="mt-2"><FormattedMessage
                        id="product-min-order-quantity-warning"
                        values={{ order_quantity: data.product_min_order_quantity }} /> </div>
                )}
            </div>

            <div className="other__options__add mt-4">
                <div className="item__quantity">
                    {/* Amount */}
                    <button type="button"><span onClick={e => setProductAmount(productAmount - 1)}>-</span>
                        {productAmount}
                        <span onClick={e => setProductAmount(productAmount + 1)}>+</span></button>
                </div>

                <div className="btn__box"><button type="button"
                    disabled={!availableChecked || !available || timeslotRentalPeriod === undefined || calculatedProductTotals.total_interval === 0}
                    onClick={e => addToShoppingcart()} className="btn__two"><FormattedMessage id="add-to-cart" /></button>
                </div>
            </div>

            {modalIsOpen && (
                <ProductGotoShoppingcartModal
                    modalIsOpen={modalIsOpen}
                    modalIsOpenHandler={modalIsOpenHandler}
                    history={history}
                />
            )}
        </div>
    );
};

export default ProductSideBarView;