import React from "react";
import { Row, Col, Image, Button, Layout, } from 'antd';
import { XCircle, Question, } from "@phosphor-icons/react";
import { FormattedMessage } from "react-intl";

const Logo = require(`./../../assets/images/${process.env.REACT_APP_LOGO_DIAP}`);
const { Content } = Layout;

const NotAuthorized = ({
  msg,
  ...props
}) => {


  return (

    <Content>
      <div className="bg-image-not-authorized" />

      <div className="container-not-authorized">
        <Row gutter={[16, 16]} align="middle" justify="space-around"
          style={{ height: "98vh", textAlign: "center" }}>

          <Col md={15} sm={20} xs={22} className="" >
            <Image src={Logo} preview={false} styleee={{ maxWidth: "400px" }} />
          </Col>

          <Col span={24}>
            <Row
              align="middle"
              justify="space-around"
              className="text-not-authorized">
              <Col span={24} className="header">
                <XCircle size={18} weight="light" className="mr-1" />
                {msg === "APPLICATION_NOT_LICENSED" ? (
                  <FormattedMessage id="APPLICATION_NOT_LICENSED" />
                ) : (
                  <FormattedMessage id="APPLICATION_NOT_FOUND" />
                )}
              </Col>

              <Col span={24}>
                The application you are looking for does not exist or has been disabled.<br />
                <Button href="https://smartrentalsolutions.nl" className="mt-2">
                  <Question size={18} weight="light" className="mr-1" /> More info
                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

      </div>
    </Content>

  );
};

export default NotAuthorized;