import React from "react";
// import { FormatEuro, } from '../../../components/helpers';
import {
    HistoryOutlined, CreditCardOutlined, SettingOutlined,
    FileProtectOutlined, FormatPainterOutlined, ColumnHeightOutlined,
    TagsOutlined, TeamOutlined
} from '@ant-design/icons';

const ProductTechSpecsView = ({
    data,
    history,
    selectedCategory = undefined,
    ...props
}) => {

    return (

        <section className="pt-6">
            <span className="heading-divider mx-auto mb-7" />
            <h4 className="fs-22 text-heading mb-6">Technische specificaties</h4>
            <div className="row">
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <TeamOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Type</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">Dames fiets</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <HistoryOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Jaartal</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">2020</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <ColumnHeightOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Wielmaat</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">22"</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <SettingOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Versnellingen</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">7 speed</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <FormatPainterOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Kleur</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">Groen</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <FileProtectOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Verzekering</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">€ 1,50</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <TagsOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Aantal</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">5</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 mb-6">
                    <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <CreditCardOutlined />
                        </div>
                        <div className="media-body">
                            <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Borg</h5>
                            <p className="mb-0 fs-13 font-weight-bold text-heading">€ 100,00</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    );
};

export default ProductTechSpecsView;