import * as api from '../api';

export const CustomerDetails = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/details/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                reject(error);
            })
    });
};

export const CustomerUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/customer/update', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                reject(error);
            })
    });
};

export const CustomerAdd = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/add', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                reject(error);
            })
    });
};

export const CustomerSettings = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/settings/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};
