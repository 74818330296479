import React, { useState, useEffect, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Spinner from "../../../../components/Spinner";
import { StatusBadge, ProfileImage, } from '../../../../components/helpers';

import { useNavigate, } from "react-router-dom";

import { Row, Card, Button, Col, Descriptions, notification, Upload, message, } from 'antd';
import { EditUserModal, } from '../users';
import { UserDetails } from '../../../../services/users';
import { ProfileImageUpload } from '../../../../services/profile';
import CustomerDetailView from './customer.detail.view';
import { FileImage, Info, Pen } from "@phosphor-icons/react";

const ProfileDetailView = ({
    ...props
}) => {

    const [userProfileData, setUserProfileData] = useState(undefined);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [updateImage, setUpdateImage] = useState(false);
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);

    const { Dragger } = Upload;
    const history = useNavigate();
    const customerID = "me";
    const intl = useIntl();

    useEffect(() => {
        if (userProfileData) {
            setDetailsLoading(false)
        };
    }, [userProfileData]);

    useEffect(() => {
        LoadProfileDetails();

        return () => {
            // cleanup
            setUserProfileData({});
            // setEntityData({});
            setDetailsLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadProfileDetails = () => {
        setDetailsLoading(true);

        // get the user profile details
        UserDetails({ user_id: customerID })
            .then(response => {
                if (response.status) {
                    setUserProfileData(response.data);
                } else {
                    history('/dashboard');
                    console.error('error:', response)
                    notification['warning']({
                        message: 'User details',
                        description: "Error on requesting profile settings data",
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                }
            });
    };

    const uploadFile = (file) => {
        setDetailsLoading(true);

        const isJpgOrPng = file.fileList[0].type === 'image/jpeg' || file.fileList[0].type === 'image/png';
        const isLt2M = file.fileList[0].size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error('Image size is to large! Use an image smaller than 2MB!');
            setDetailsLoading(false);
            return false;
        }

        if (!isJpgOrPng) {
            message.error('File not allowed. You can only upload jepg or png file.');
            setDetailsLoading(false);
            return false;
        }

        const post_data = {
            user_id: customerID,
            file_name: file.fileList[0].name,
            file_type: file.fileList[0].type,
        };

        ProfileImageUpload(post_data, history).then(response => {
            if (response.status) {

                // upload document using temporarly settings
                // let file_name = response.data.file_name;
                let formData = new FormData();
                formData.append('key', response.data.upload.fields.key)
                formData.append('policy', response.data.upload.fields.policy)
                formData.append('x-amz-algorithm', response.data.upload.fields["x-amz-algorithm"])
                formData.append('x-amz-credential', response.data.upload.fields["x-amz-credential"])
                formData.append('x-amz-date', response.data.upload.fields["x-amz-date"])
                formData.append('x-amz-signature', response.data.upload.fields["x-amz-signature"])
                formData.append('File', file.file.originFileObj);

                fetch(response.data.upload.url, {
                    method: "POST",
                    data: response.data.upload.fields,
                    body: formData,
                })
                    .then(upload_response => {
                        if (upload_response.ok && upload_response.status === 204) {
                            notification['success']({
                                message: 'Profile image upload',
                                description: intl.formatMessage({ id: "IMAGE_UPLOAD_SUCCESS" }),
                            });
                            LoadProfileDetails();
                            setUpdateImage(false);
                        }
                    })
                    .catch(error => {
                        console.error('error: ', error);
                        setUpdateImage(false);
                    });

            } else {
                notification['Error']({
                    message: 'Profile image upload',
                    description: intl.formatMessage({ id: response.msg }),
                });
                setUpdateImage(false);
            }
        });
    };

    if (detailsLoading) return <Spinner />;

    return (
        <Fragment>

            <Row gutter={[32, 32]}>
                <Col span={24}>

                    {/* Status and availabilty banner */}
                    {/* <Card>
                        BANNNNEEEERRR
                    </Card> */}

                    {/* Profile basic card */}
                    <Card
                        title={<h3 className="card-title"><FormattedMessage id="profile-user-details" /></h3>}
                        className=""
                        // loading={detailsLoading}
                        extra={[
                            <Button
                                key="1"
                                // className="ant-btn-success"
                                onClick={() => { setEditCustomerOpen(!editCustomerOpen) }}
                                icon={<Pen size={18} weight="light" className="mr-1" />}>
                                <FormattedMessage id="profile-edit" />
                            </Button>,
                        ]}>

                        {/* user profile detauls */}
                        {!detailsLoading && (
                            <Descriptions bordered column={1}>
                                <Descriptions.Item label={<FormattedMessage id="user-image" />}>
                                    {updateImage ? (
                                        <Dragger
                                            onChange={uploadFile}
                                            // beforeUpload={beforeUpload}
                                            showUploadList={false}
                                            className="p-3">
                                            <p className="ant-upload-drag-icon">
                                                <FileImage size={50} weight="light" />
                                            </p>
                                            <p className="ant-upload-text"><FormattedMessage id="file-uploader" /></p>
                                            <p className="ant-upload-hint"><Info size={18} weight="light" /> <FormattedMessage id="file-uploader-image-info" /></p>
                                        </Dragger>
                                    ) : (
                                        <>
                                            <ProfileImage
                                                image={userProfileData?.user_data?.user_profile_image ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${userProfileData?.user_data?.user_profile_image}` : undefined}
                                                email={userProfileData?.user_email} />
                                            <Button
                                                size="small"
                                                style={{ margin: '0 16px', verticalAlign: 'middle' }}
                                                onClick={e => setUpdateImage(true)}>
                                                Change Image
                                            </Button>
                                        </>
                                    )}

                                    {/* {!userProfileData.user_data.user_profile_image ? (

                                    <Button
                                        size="small"
                                        style={{ margin: '0 16px', verticalAlign: 'middle' }}
                                        className="ant-btn-success"
                                        icon={<PlusSquareOutlined />}
                                        onClick={e => setUpdateImages(true)}
                                    >
                                        Add image
                                    </Button>
                                ) : (

                                )} */}

                                </Descriptions.Item>
                                <Descriptions.Item label={<FormattedMessage id="user-status" />}><StatusBadge status={userProfileData?.user_status} /></Descriptions.Item>
                                <Descriptions.Item label={<FormattedMessage id="user-name" />}>{userProfileData?.user_data?.user_first_name} {userProfileData?.user_data?.user_last_name}</Descriptions.Item>
                                <Descriptions.Item label={<FormattedMessage id="emailaddress" />}>{userProfileData?.user_email}</Descriptions.Item>
                                <Descriptions.Item label={<FormattedMessage id="phonenumber" />}>{userProfileData?.user_data?.user_phone_number}</Descriptions.Item>
                                <Descriptions.Item label={<FormattedMessage id="display-language" />}>{userProfileData?.user_data?.user_language}</Descriptions.Item>


                            </Descriptions>
                        )}
                    </Card>

                    <div className="mt-4">
                        <CustomerDetailView
                            history={history}
                            customerID={customerID}
                            setVendorName={() => { }}
                            refreshData={LoadProfileDetails}
                        />
                    </div>

                </Col>
            </Row>

            {editCustomerOpen && (
                <EditUserModal
                    modalIsOpen={editCustomerOpen}
                    modalIsOpenHandler={setEditCustomerOpen}
                    userProfileData={userProfileData}
                    refreshData={LoadProfileDetails}
                    history={history} />
            )}
        </Fragment>
    );
};

export default ProfileDetailView;