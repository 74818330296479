import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Moment from 'react-moment';
import { ProfileImage } from '../../../../../components/helpers';
import { Table, Row, Button, Col, Tag, Tooltip } from 'antd';
import { EditUserModal, DeleteUserModal, } from '../index';
import { UserGear, UserMinus, Users, } from "@phosphor-icons/react";

const UserGridView = ({
    dataLoading = true,
    dataTable,
    refreshData,
    history,
}) => {

    // modals
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
    const [userProfileData, setUserProfileData] = useState({});

    const editUserModalHandler = (data) => {
        setUserProfileData(data);
        setEditUserModalOpen(true);
    };

    const deleteUserModalHandler = (data) => {
        setUserProfileData(data);
        setDeleteUserModalOpen(true);
    };

    const columns = [
        {
            title: <FormattedMessage id="username" />,
            dataIndex: 'user_email',
            key: 'user_email',
            render: (user_email, record) => (
                <Row align="middle" justify="space-around">
                    <Col span={5}>
                        {record.user_data.user_profile_image ? (
                            <ProfileImage size="sm" image={`https://${process.env.REACT_APP_AWS_DATASTORE}/${record.user_data.user_profile_image}`} className="rounded-full" />
                        ) :
                            (
                                <ProfileImage size="sm" email={user_email} className="rounded-full" />
                            )}
                    </Col>
                    <Col span={17} className="ml-2">
                        <div className="font-weight-bolder">{record.user_data?.user_first_name} {record.user_data.user_last_name}</div>
                        <div className="font-weight-light">{user_email}</div>
                    </Col>
                </Row>
            )
        },
        {
            title: <FormattedMessage id="registration-date" />,
            dataIndex: 'user_created',
            key: 'user_created',
            render: user_created => (<Moment format="DD-MM-YYYY" date={user_created} />),
        },
        {
            title: <FormattedMessage id="status" />,
            dataIndex: 'user_status',
            key: 'user_status',
            align: 'center',
            render: user_status => (
                <Tag color={user_status === 'ACTIVE' ? 'green' : 'volcano'}>{ } {user_status.toUpperCase()}</Tag>
            )
        },
        {
            title: <FormattedMessage id="permission-group" />,
            dataIndex: 'user_data',
            key: 'user_data',
            align: 'center',
            render: user_data => (
                <>
                    {user_data.user_group === 'administrators' && (<Tag variant="danger"><Users size={16} weight="light" className="mr-1" />  {user_data.user_group}</Tag>)}
                    {user_data.user_group === 'operators' && (<Tag variant="warning"><Users size={16} weight="light" className="mr-1" /> {user_data.user_group}</Tag>)}
                    {user_data.user_group === 'users' && (<Tag variant="success"><Users size={16} weight="light" className="mr-1" /> {user_data.user_group}</Tag>)}
                </>
                // <Tag color={user_status === 'ACTIVE' ? 'green' : 'volcano'}>{ } {user_status.toUpperCase()}</Tag>
            )
        },
        {
            title: "",
            dataIndex: "button",
            key: "button",
            align: 'center',
            render: (button, record) => (
                <>
                    <Tooltip placement="top" title={<FormattedMessage id="edit-user-open-tt" />}>
                        <Button
                            // type="primary"
                            icon={<UserGear size={16} className="mr-1" />}
                            size="small"
                            onClick={() => { editUserModalHandler(record) }}
                            className="mr-1"
                        />
                    </Tooltip>

                    <Tooltip placement="top" title={<FormattedMessage id="delete-user-open-tt" />}>
                        <Button
                            type="primary"
                            danger
                            icon={<UserMinus size={16} weight="light" />}
                            size="small"
                            onClick={() => { deleteUserModalHandler(record) }}
                            className="mr-1"
                        />
                    </Tooltip>
                </>
            )
        }
    ];

    return (
        <React.Fragment>

            <Table
                rowKey={record => record.user_id}
                dataSource={dataTable} columns={columns} loading={dataLoading} />

            {editUserModalOpen && (
                <EditUserModal
                    modalIsOpen={editUserModalOpen}
                    modalIsOpenHandler={setEditUserModalOpen}
                    userProfileData={userProfileData}
                    refreshData={refreshData}
                    history={history} />
            )}

            {deleteUserModalOpen && (
                <DeleteUserModal
                    modalIsOpen={deleteUserModalOpen}
                    modalIsOpenHandler={setDeleteUserModalOpen}
                    userProfileData={userProfileData}
                    refreshData={refreshData}
                    history={history} />
            )}

        </React.Fragment>

    )
}

export default UserGridView;