import React, { useState, } from 'react';
import { Row, Col, } from 'antd';
import DownloadsModal from '../account.modals/download.modal';
import { FacebookLogo, InstagramLogo, LinkedinLogo, XLogo, YoutubeLogo } from '@phosphor-icons/react';

const Logo = require(`./../../assets/images/${process.env.REACT_APP_LOGO}`);

const PageFooter = ({
    setIsContactModalOpen,
    isContactModalOpen,
    ...props
}) => {

    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const { branding_name, branding_logo, branding_phone, branding_socials,
        branding_slogan, templates, id, branding_remove_banner, } = props;
    const { branding_financial: { prices_include_vat } = {} } = props;

    return (
        <footer className="main__footer p_relative">
            <div className="main__footer__top">
                <div className="footer__middle  p_relative d_block">
                    <div className="auto_container">
                        <div className="footer__middle__content">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-md-12 col-sm-12 footer_column">
                                    <div className="footer_widget left">
                                        <div className="footer__logo text-center">
                                            <figure>
                                                <img style={{ maxWidth: "300px" }} src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} alt="" />
                                            </figure>
                                        </div>
                                        <div className="widget_content text-center">
                                            <p>{branding_slogan}</p>
                                        </div>
                                        {branding_socials && (
                                            <div className="widget_media text-center">
                                                <ul>
                                                    {branding_socials.facebook_enable && (
                                                        <li><a href={branding_socials.facebook_link} target="_blank" rel="noreferrer noopener"><FacebookLogo weight='light' size={40} /></a></li>
                                                    )}

                                                    {branding_socials.twitter_enable && (
                                                        <li><a href={branding_socials.twitter_link} target="_blank" rel="noreferrer noopener"><XLogo weight='light' size={40} /></a></li>
                                                    )}

                                                    {branding_socials.instagram_enable && (
                                                        <li><a href={branding_socials.instagram_link} target="_blank" rel="noreferrer noopener"><InstagramLogo weight='light' size={40} /></a></li>
                                                    )}

                                                    {branding_socials.linkedin_enable && (
                                                        <li><a href={branding_socials.linkedin_link} target="_blank" rel="noreferrer noopener"><LinkedinLogo weight='light' size={40} /></a></li>
                                                    )}

                                                    {branding_socials.youtube_enable && (
                                                        <li><a href={branding_socials.youtube_link} target="_blank" rel="noreferrer noopener"><YoutubeLogo weight='light' size={40} /></a></li>
                                                    )}

                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <div className="col-lg-3 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget links_widget ml_100">
                                        <div className="widget_title">
                                            <h4>About Us</h4>
                                        </div>
                                        <div className="widget_content">
                                            <ul className="links_list clearfix">
                                                <li><a href="!#">About Us</a></li>
                                                <li><a href="!#">Start a Return</a></li>
                                                <li><a href="!#">Contact Us</a></li>
                                                <li><a href="!#">Shipping FAQ</a></li>
                                                <li><a href="!#">Careers</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget links_widget">
                                        <div className="widget_title">
                                            <h4>Others Links</h4>
                                        </div>
                                        <div className="widget_content">
                                            <ul className="links_list clearfix">
                                                <li><a href="!#">Help Center</a></li>
                                                <li><a href="!#">Delivery</a></li>
                                                <li><a href="!#">Return Policy</a></li>
                                                <li><a href="!#">Policies</a></li>
                                                <li><a href="!#">Gift Cards</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                                {/* News letter signup */}
                                {/* <div className="col-lg-4 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget news__letter">
                                        <div className="widget_title">
                                            <h4>Newsletter </h4>
                                        </div>
                                        <div className="widget_content">
                                            <p>Get the latest news, events & more delivered to your inbox.</p>
                                            <div className="news__letter">
                                                <form action="/">
                                                    <input type="text" placeholder="Email Address" name="mail" required />
                                                    <button className="submit" id="submit"><i
                                                        className="flaticon-right-arrow"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_bottom p_relative">
                <div className="auto_container">

                    <Row className="text-center bottom_inner">
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <p>{branding_name}</p>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <p>{branding_phone}</p>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <p>Onze prijzen zijn {prices_include_vat ? 'inclusief' : 'exclusief'} BTW</p>
                        </Col>
                    </Row>

                </div>

                <div className="auto_container">
                    <Row className="text-center bottom_inner">
                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <p>© {new Date().getFullYear()}, All Rights Reserved.</p>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                            <p className="pointer" onClick={e => { setOpenDownloadModal(true) }}>Terms</p>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                            <p className="pointer" onClick={e => { setOpenDownloadModal(true) }}>Privacy</p>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <p>
                                {!branding_remove_banner && (
                                    <a href="https://smartrentalsolutions.nl">Powered by Smart Rental Solutions B.V.</a>
                                )}
                            </p>
                        </Col>
                    </Row>


                </div>
            </div>

            {openDownloadModal && (
                <DownloadsModal
                    modalIsOpen={openDownloadModal}
                    modalIsOpenHandler={setOpenDownloadModal}
                    templates={templates}
                    id={id}
                />
            )}

        </footer>

    )
};

export default PageFooter;