import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Spinner from "../../../../../components/Spinner";

import { Row, Card, Button, Col, notification } from 'antd';
import { AddUserModal, UserGridView } from '../index';
import { UserList } from '../../../../../services/users';
import { UserPlus } from "@phosphor-icons/react";


const UsersView = ({
    dataLoading,
    history,
    customerID = "me",
}) => {

    const [userListData, setUserListData] = useState([]);
    const [userListLoading, setUserListLoading] = useState(true);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        // loading users
        LoadUserList();
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const LoadUserList = () => {
        setUserListLoading(true);
        UserList(customerID)
            .then(response => {
                if (response.status) {
                    setUserListData(response.data.user_list);
                    setUserListLoading(false);
                } else {
                    history('/dashboard');
                    console.warn('error:', response)
                    notification['warning']({
                        message: 'User details',
                        description: "Error on requesting profile settings data",
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                }
            });
    };

    if (dataLoading && userListLoading) return <Spinner />;

    return (
        <React.Fragment>
            <Row gutter={[32, 32]}>
                <Col span={24}>

                    <Card
                        title={<h3 className="card-title"><FormattedMessage id="profile-users-header" /></h3>}
                        className=""
                        styles={{ body: { paddingLeft: '0px', paddingRight: '0px' } }}
                        extra={[
                            <Button
                                key="1"
                                className="ant-btn-success"
                                onClick={() => { setAddUserOpen(!addUserOpen) }}
                                icon={<UserPlus size={18} weight="light" className="mr-1" />}>
                                <FormattedMessage id="user-add" />
                            </Button>,
                        ]}>

                        <UserGridView
                            dataLoading={userListLoading}
                            dataTable={userListData}
                            refreshData={LoadUserList}
                            history={history}
                        />

                    </Card>
                </Col>
            </Row>

            {addUserOpen && (
                <AddUserModal
                    modalIsOpen={addUserOpen}
                    modalIsOpenHandler={setAddUserOpen}
                    customerID={customerID}
                    refreshData={LoadUserList}
                    history={history}
                />
            )}

        </React.Fragment >
    );
};


export default UsersView;