import React, { useEffect } from "react";
// import { FormattedMessage } from "react-intl";
import { Row, Col, Image, Button, Layout, } from 'antd';
import { Helmet } from "react-helmet-async";
import { Plugs, Question } from "@phosphor-icons/react";

const Logo = require(`./../../assets/images/${process.env.REACT_APP_LOGO_DIAP}`);
const { Content } = Layout;

const ErrorPage = ({
  refresh,
  ...props
}) => {

  const { branding_name, branding_logo, branding_slogan, } = props.themeData;
  useEffect(() => {
    const timer = setInterval(() => {
      checkConnection();
    }, 5000);

    // cleanup
    return () => {
      clearInterval(timer);
    }
    // eslint-disable-next-line
  }, []);


  const checkConnection = () => {
    refresh();
  };


  return (

    <Content>

      <Helmet>
        <title>{branding_name}</title>
        <meta name="description" content={branding_slogan} />
        <meta property="og:title" content={branding_slogan} />
        {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
        {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
        {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
        <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
      </Helmet>

      <div className="bg-image-no-connection" />

      <div className="container-no-connection">
        <Row gutter={[16, 16]} align="middle" justify="space-around"
          style={{ height: "98vh", textAlign: "center" }}>

          <Col md={15} sm={20} xs={22} className="" >
            <Image src={Logo} preview={false} styleee={{ maxWidth: "400px" }} />
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-around"
              className="text-no-connection">
              <Col span={24} className="header">
                <Plugs size={30} className="mr-2" /> <span>Connection error</span>
              </Col>

              <Col span={24}>
                Do you see this page, the connection to our API has failed. <br />
                We try to re-connect you, please wait....<br />
                <Button href="https://smartrentalsolutions.nl" className="mt-2">
                  <Question size={18} weight="light" className="mr-1" /> More info
                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

      </div>
    </Content>

  );
};

export default ErrorPage;