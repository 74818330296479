import * as api from '../api';


export const ListDocuments = (entity_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/customer/document/list/' + entity_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};


export const DocumentPrepairUpload = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/document/prepair_upload', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
    });
};


export const TemplatePrepairUpload = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/settings/template/add', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
    });
};



export const DocumentDownload = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/document/download', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const DocumentUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/customer/document/details', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
    });
};

export const DocumentDetails = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/customer/document/details', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
    });
};

export const DocumentDelete = (data) => {
    return new Promise((resolve, reject) => {
        api
            .remove('/customer/document/delete', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
    });
};