import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Moment from 'react-moment';
import { Row, Col, Skeleton, List, Empty, Table, Tag, Card, } from 'antd';
import EmptyImage from '../../../../assets/images/empty.svg';

import { ListDocuments } from '../../../../services/documents';
import DocumentDownloadModal from './modals/document.download.modal';
import { File, FileCsv, FileDoc, FileJpg, FilePdf, FilePng, FileVideo, FileXls, FileZip, Image, User } from "@phosphor-icons/react";

const DocumentsGridView = ({
    customerID,
    history,
    ...props
}) => {

    const [documents, setDocuments] = useState([]);
    const [isDownloadDocumentOpen, setIsDownloadDocumentOpen] = useState(false);
    const [modalOpenData, setModalOpenData] = useState(undefined);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {

        LoadDocuments();

        return () => {
            // cleanup
            setDocuments([]);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDocuments])

    const LoadDocuments = () => {
        setLoadingData(true);
        setDocuments([]);

        ListDocuments(customerID)
            .then(response => {
                setDocuments(response.data.documents);
                setLoadingData(false);
            })
            .catch(error => {
                console.error('error: ', error);
            });
    };

    const DocumentTypeBadge = (p_DocumentType) => {
        switch (p_DocumentType) {
            case "CUSTOM":
                return (<Tag color="geekblue">
                    <User size={16} /> <FormattedMessage id={p_DocumentType} />
                </Tag>);

            case "TEST_DRIVE_CONTRACT":
            case "PURCHASE_CONTRACT":
                return (<Tag color="green" >
                    <Image size={16} /> <FormattedMessage id={p_DocumentType} />
                </Tag>);

            default:
                return (<Tag color="cyan" >
                    <File size={16} /> <FormattedMessage id={p_DocumentType || "UNKNOWN"} />
                </Tag>);

        }
    };

    const fileIcon = (p_FileType) => {
        switch (p_FileType) {
            case "application/pdf":
                return <FilePdf size={24} weight="light" className="mr-1" />;
            case "image/jpeg":
                return <FileJpg size={24} weight="light" className="mr-1" />;
            case "image/png":
                return <FilePng size={24} weight="light" className="mr-1" />;
            case "text/csv":
                return <FileCsv size={24} weight="light" className="mr-1" />;
            case "application/zip":
                return <FileZip size={24} weight="light" className="mr-1" />;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <FileDoc size={24} weight="light" className="mr-1" />;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <FileXls size={24} weight="light" className="mr-1" />;
            case "video/quicktime":
                return <FileVideo size={24} weight="light" className="mr-1" />;

            default:
                console.warn('file type not set for: ', p_FileType);
                return <File size={24} weight="light" className="mr-1" />;
        }
    };

    const clickTableRow = (data) => {
        if (data.document_type === "QUOTATION" && data?.document_data?.booking_id) {
            history(`/quote/details/${data.booking_id}`);
        } else {
            data.customer_id = customerID;
            setModalOpenData(data);
            setIsDownloadDocumentOpen(true);
        }
    };

    const columns = [
        {
            title: <FormattedMessage id="document-file-type" />,
            dataIndex: 'document_type',
            key: 'document_type',
            align: 'center',
            width: 175,
            render: (document_type, record) => (
                <span>{fileIcon(record.document_file_type)}</span>
            )
        },
        {
            title: <FormattedMessage id="document-type" />,
            dataIndex: 'document_type',
            key: 'document_type',
            align: 'center',
            width: 175,
            render: (document_type, record) => (
                <div>{DocumentTypeBadge(record.document_type)}</div>

            )
        },
        {
            title: <FormattedMessage id="document-description" />,
            dataIndex: 'document_description',
            key: 'document_description',
            render: (document_description, record) => (
                <>{record.document_description}</>
            )
        },
        {
            title: <FormattedMessage id="create-date" />,
            dataIndex: 'document_added',
            // width: 175,
            key: 'document_added',
            render: document_added => (<Moment format="DD-MM-YYYY" date={document_added} />),
        },
    ];

    return (
        <Row gutter={[32, 32]}>

            {loadingData ? (
                <Col span={24}>
                    <Skeleton loading={loadingData} active className="bg-white p-3">
                        <List.Item.Meta
                        />
                    </Skeleton>
                </Col>
            ) : (
                <>
                    {documents.length === 0 ? (
                        <Col span="24" className="">
                            <Empty
                                className="p-3 ant-empty-custom mt-4 bg-white"
                                image={EmptyImage}
                                imageStyle={{ height: 60, }}
                                description={
                                    <span>
                                        There are currently no documents.
                                    </span>
                                }>

                            </Empty>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Card
                                title={<h3 className="card-title"><FormattedMessage id="documents-header" /></h3>}
                                className="mt-4"
                                styles={{ body: { paddingLeft: '0px', paddingRight: '0px' } }}
                                extra={[]}>

                                <Table
                                    className=""
                                    rowKey={record => record.document_id}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { clickTableRow(record) }, // click row
                                            onDoubleClick: event => { }, // double click row
                                            onContextMenu: event => { }, // right button click row
                                            onMouseEnter: event => { }, // mouse enter row
                                            onMouseLeave: event => { }, // mouse leave row
                                        };
                                    }}
                                    // rowSelection={{ type: 'checkbox', ...rowSelection, }}
                                    rowClassName="pointer"
                                    dataSource={documents}
                                    pagination={{ pageSize: 500 }}
                                    columns={columns}
                                    loading={loadingData} />
                            </Card>
                        </Col>
                    )}</>
            )}

            {isDownloadDocumentOpen && (
                <DocumentDownloadModal
                    modalIsOpen={isDownloadDocumentOpen}
                    modalIsOpenHandler={setIsDownloadDocumentOpen}
                    documentData={modalOpenData}
                />
            )}
        </Row>
    );
};

export default DocumentsGridView;