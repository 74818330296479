
import React, { useState, } from "react";
import { Form, Input, notification, Row, Col } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';

import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { RequestPassword } from '../../../services/users';
import { CaretLeft, EnvelopeSimple } from "@phosphor-icons/react";


const RequestPasswordFormView = ({
    modalIsOpenHandler,
    setShowForm,
    history,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const intl = useIntl();
    const { handleSubmit, control } = useForm(
        { mode: "all" }, // set the field validation mode
    );

    const onSubmit = (data) => {
        setButtonDisabled(true);

        RequestPassword(data)
            .then(response => {
                if (response.status) {
                    // setMailaddress(data.user_email);
                    setButtonDisabled(false);
                    notification['success']({
                        message: 'Request password',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    modalIsOpenHandler(false);
                    setShowForm("LOGIN")
                } else {
                    setButtonDisabled(false);
                    notification['error']({
                        message: 'Request password',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                };
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Request password',
                    description: "An error occurred. Please check the browser console for more information",
                });

                setButtonDisabled(false);
            });
    };

    return (
        <React.Fragment>

            <p><FormattedMessage id="login-pwd-request-info" /></p>
            <FormattedMessage id="emailaddress">
                {NameField =>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="user_email"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                message: "EmailAddressNotValid",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <Form.Item
                                help={error && intl.formatMessage({ id: error?.message })}
                                hasFeedback
                                validateStatus={error?.message && "error"}
                                label={<strong>{NameField}</strong>}
                                required>
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    placeholder={NameField}
                                    prefix={<EnvelopeSimple size={18} weight="light" className="site-form-item-icon" />}
                                // onChange={(e) => { props.onChange(e) }}
                                // checked={props.value}
                                />
                            </Form.Item>
                        )}
                    />
                }
            </FormattedMessage>

            <div className="text-center mt-4">
                <div className="btn__box">
                    <button className="btn-one pointer"
                        onClick={handleSubmit(onSubmit)}
                        disabled={buttonDisabled}>
                        <span className="txt text-center">
                            {buttonDisabled ? (
                                <LoadingOutlined />) : (
                                <FormattedMessage id="request-password" />
                            )}
                        </span>
                    </button>
                </div>
            </div>

            <Row className="mt-3 login-footer">
                <Col span={24}><CaretLeft size={18} weight="light" /> <span className="pointer" onClick={e => setShowForm("LOGIN")}><FormattedMessage id="back" /></span></Col>
            </Row>

        </React.Fragment>
    );
};

export default RequestPasswordFormView;