import React from "react";
import SearchBannerForm from '../../search';
import SearchBannerWebshopForm from '../../search/search.banner.webshop';
import Background from '../../../assets/images/palceholder_home.jpg';

const HomePageHero = ({
    auth,
    history,
    ...props

}) => {


    const { branding_homepage_image, branding_name, branding_slogan, branding_webshop_disable_rental, } = props.themeData;


    return (

        <section className="banner__section sec__pad">
            <div className="auto_container">
                <div className="row">
                    <div className="col-xl-6 col-lg-12" >
                        <div className="banner__content__left">

                            <div className="banner__title">
                                <h1>{branding_name}</h1>
                            </div>

                            <div className="banner__text">
                                <p>{branding_slogan}</p>
                            </div>

                            <div className="banner__form">
                                <div className="banner__from__content">
                                    {branding_webshop_disable_rental ? (
                                        <SearchBannerWebshopForm {...props} />
                                    ) : (
                                        <SearchBannerForm {...props} />
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12">
                        <div className="banner__content__right">
                            <figure className="banner__right">
                                <img
                                    src={branding_homepage_image ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_homepage_image}` : Background}
                                    // src={branding_homepage_image }
                                    alt="" />
                            </figure>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );
};


export default HomePageHero;