import React, { useState, useEffect, } from "react";
import { FormattedMessage, } from "react-intl";
import { FormatEuro, getLowestPrice, } from '../../../components/helpers';
import truncate from '../../../components/helpers/truncate.html';
import ProductPhotoView from '../detail.views/product.photo.view';
import ProductSideBarView from './product.side.bar';
import { useLocation, Link, } from 'react-router-dom';
import { Tabs, } from 'antd';
// import ProductOptionalView from '../detail.views/product.optional.view';
import ProductLocationsView from '../detail.views/product.locations.view';

const ProductDetailStandardPage = ({
    productData,
    selectedCategory,
    options,
    history,
    ...props

}) => {

    const { language } = props;
    const { state } = useLocation();
    const [mandatoryOptions, setMandatoryOptions] = useState([]);

    useEffect(() => {
        if (selectedCategory && options) {
            getMandatoryOptions();
        }

        // eslint-disable-next-line
    }, [selectedCategory, options]);

    const getMandatoryOptions = () => {
        // get only the posible options (get from product category cat_options)
        var possible_options = [];

        selectedCategory?.cat_options.map((item, index) => {

            // Get the option from the options array
            var option = options.find((element) => {
                if (element.product_pricing.option_mandatory) {
                    return element.product_id === item && element.product_enabled;
                } else {
                    return false;
                }
            });

            if (option) {
                // option.parent_product_data = productData;
                possible_options.push(option);
            }
            return true;
        });
        setMandatoryOptions(possible_options);
    };

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    const items = [
        {
            key: '1',
            label: <FormattedMessage id="product-locations" />,
            children: <ProductLocationsView data={selectedCategory?.cat_locations} history={history} {...props} />,
            className: 'tab__btn',
        },
        // {
        //     key: '2',
        //     label: <FormattedMessage id="product-options" />,
        //     children: <ProductOptionalView options={options} {...props} />,
        // },
    ];

    return (
        <section className="product__details">
            <div className="auto_container">
                <div className="product__details__content">

                    {/* Bread crums */}
                    <div className="bread__cum">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>/</li>
                            <li><Link to="/products" state={{ cat_id: productData?.product_category, date_range: state?.date_range }}><FormattedMessage id="products" /></Link></li>
                            <li>/</li>
                            <li>{productData?.product_names?.[`product_name_${language}`] || productData?.product_names?.product_name_nl || productData?.product_name}</li>
                        </ul>
                    </div>

                    {/* Product standard container */}
                    <div className="row clearfix">

                        {/* Photo cointainer left */}
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <ProductPhotoView data={productData?.product_images || []} />
                        </div>

                        {/* Details container right */}
                        <div className="col-lg-6 col-md-12 col-sm-12 content__column">
                            <React.Fragment>
                                {productData && (
                                    <div className="product__info">
                                        <h2>{productData.product_names?.[`product_name_${language}`] || productData.product_names?.product_name_nl || productData.product_name}</h2>
                                        <div className="price__item">

                                            {/* Price details for rental products */}
                                            <span className="item__price"><FormatEuro amount={getLowestPrice(productData, mandatoryOptions)} /></span>
                                            <span className="per__day"><FormattedMessage id={`from-per-${productData.product_pricing.product_time_slots[0].ts_interval_type.toLowerCase()}`} /></span>


                                        </div>

                                        {/* <div className="customer__review ">
                        <ul className="rating__box ">
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                        <div className="reviews"><span>(5 Reviews)</span></div>
                    </div>

                    <div className="pepole__view">
                        <li><i className="flaticon-view"></i> 21 people are viewing this right now</li>
                    </div> */}

                                        <div className="product__color mt-4" style={{ minHeight: "150px" }}>
                                            {renderHTMLContent(truncate(productData.product_descriptions?.[`product_description_${language}`] || productData.product_descriptions?.product_description_nl || productData.product_description, 150))}

                                            {/* <div className="color__title">
                            <h6>Beschikbare opties</h6>
                        </div>
                        <ul>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                            <li><img src="assets/images/products/color.png" alt="" /></li>
                        </ul> */}
                                        </div>

                                        <div className="product-reservation-container">
                                            <ProductSideBarView
                                                options={options}
                                                data={productData}
                                                history={history}
                                                selectedCategory={selectedCategory}
                                                {...props} />
                                        </div>


                                        {/* <ul className="share__option">
                        <li><i className="flaticon-question-mark"></i> ASK ABOUT THIS PRODUCT</li>
                        <li><i className="flaticon-share"></i> SHARE</li>
                    </ul> */}

                                        <div className="other__links">
                                            {/* <ul className="list__link">
                            <li><span>Vendor:</span>Honda</li>
                            <li><span>Availability:</span> In Stock</li>
                            <li><span>SKU:</span> {data.product_sku}</li>
                            <li><span>Collection:</span>All</li>
                        </ul> */}
                                        </div>

                                    </div>
                                )}
                            </React.Fragment>
                        </div>
                    </div>

                </div>

                {/* Tab container */}
                <div className="product__discription">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="tabs__box">
                            <div className="tab__btn__box">
                                <Tabs defaultActiveKey="1" items={items} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    );
};

export default ProductDetailStandardPage;