import React, { useState } from "react";

import { Button, Modal, notification, Form, Switch, Card } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { DisableUserMFA } from '../../../../../services/users';
import { Power, X } from "@phosphor-icons/react";

const DisableUserMFAModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    refreshData,
    userID,
    history
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const intl = useIntl();

    const onSubmit = () => {
        setButtonDisabled(true);
        setConfirmDelete(false);

        DisableUserMFA({ user_id: userID })
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'MFA settings',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                } else {
                    notification['warning']({
                        message: 'MFA settings',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                }
                modalIsOpenHandler(false);
                refreshData();
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                } else {
                    notification['error']({
                        message: 'MFA settings',
                        description: "Error loading customer data. Please refresh browser and try again!",
                    });
                }

                setButtonDisabled(false);
            });
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><Power size={18} weight="light" className="mr-1" /> <FormattedMessage id="disable-mfa-header" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            className="user danger"
            // bodyStyle={{ backgroundColor: '#cf1322', color: '#fff' }}
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></Button >,
                <Button key="2"
                    danger={true}
                    type="primary"
                    loading={buttonDisabled}
                    disabled={!confirmDelete}
                    onClick={onSubmit}>
                    {!buttonDisabled && (<Power size={18} weight="light" className="mr-1" />)} <FormattedMessage id="mfa-disable" /></Button>,
            ]}>

            <p><FormattedMessage id="disable-mfa-info" /></p>
            <Card sizee="small" title={<><strong><FormattedMessage id="disable-mfa-header" /></strong>?</>}>
                <Form
                // labelCol={{ span: 20 }}
                // wrapperCol={{ span: 4 }}
                >

                    <Form.Item label={<FormattedMessage id="disable-mfa-confirm" />} valuePropName="checked">
                        <Switch
                            className="danger"
                            checked={confirmDelete}
                            onChange={e => setConfirmDelete(!confirmDelete)}
                        />
                    </Form.Item>

                </Form>
            </Card>

        </Modal>
    );
};


export default DisableUserMFAModal;
