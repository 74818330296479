import React, { useState, } from "react";
import { Form, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { FormatEuro, } from '../../../components/helpers';
// import dayjs from 'dayjs';
import { format } from 'date-fns';
import ProductTimeSlotSelectorModal from './product.timeslot.selector.modal';

import 'react-datepicker/dist/react-datepicker.css';
import './timeslot.css';


const ProductSideBarTimeSlotView = ({
    history,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    timeslots,
    timeslotRentalPeriod,
    ...props
}) => {

    const { themeData, } = props;
    const { branding_financial, } = themeData;
    const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);

    return (

        <React.Fragment>

            <div className="text-center">
                <h4>
                    <FormattedMessage id="select-date-time-below" />
                </h4>
            </div>

            <Form.Item>
                <div className="other__options__add mt-4">
                    <div className="btn__box">
                        <button type="button"
                            onClick={e => setOpenTimeSlotModal(true)}
                            className="btn__two">
                            {selectedDate.length === 2 ? `${format(selectedDate[0], 'dd-MM-yyyy')} ${selectedTime}` : (<span>Kies een datum en tijd</span>)}
                        </button>
                    </div>
                </div>
            </Form.Item>

            {selectedDate && branding_financial.minimal_24hours_order && (
                <div className="mb-4">
                    <p style={{ color: "red" }}><small><FormattedMessage id="minimal-24hours-order-warning" /></small></p>
                </div>
            )}

            {selectedDate && timeslotRentalPeriod === undefined && (<span className="text-danger"><FormattedMessage id="select-period-not-available" /></span>)}
            {timeslotRentalPeriod?.ts_interval_pricing?.length === 0 && (<span className="text-danger"><FormattedMessage id="select-period-for-pricing" /></span>)}

            <React.Fragment>
                {timeslotRentalPeriod?.ts_interval_pricing?.map((item, index) => (
                    <div key={index}>
                        {item.interval_amount === 1 && (<div><small><FormattedMessage id={`price-per-${timeslotRentalPeriod?.ts_interval_type.toLowerCase()}`} />: <FormatEuro amount={item.interval_price} /></small></div>)}
                        {item.interval_amount > 1 && (<div><small><FormattedMessage id="price-on" /> {item.interval_amount} <FormattedMessage id={`${timeslotRentalPeriod?.ts_interval_type.toLowerCase()}s`} />: <FormatEuro amount={item.interval_price} /></small></div>)}
                    </div>
                ))}
            </React.Fragment>

            <ProductTimeSlotSelectorModal
                modalIsOpen={openTimeSlotModal}
                modalIsOpenHandler={setOpenTimeSlotModal}
                timeslots={timeslots}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                {...props} />

        </React.Fragment>
    );
};

export default ProductSideBarTimeSlotView;
