import React, { useState, useEffect, } from "react";
import { useFetchAuth } from './../../services/authentication/auth';
import { ApplicationStatus } from '../../services/public';
import HeaderBar from '../header/header.protected';
import Spinner from './../Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import DashboardMenu from '../../cointainers/dashboard/dashboard.menu';
import { CustomCSS } from "../helpers";
import ReactGA from "react-ga4";


const { Header, Content, Sider, } = Layout;

const ProtectedLayout = ({
    children,
    setAuth,
    ...props
}) => {

    const { setThemeData, GApageView, } = props;
    const { pathname } = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [appData, setAppData] = useState({});
    const { auth, loading } = useFetchAuth({ required: true });
    const history = useNavigate();
    GApageView(window.location.pathname);

    // const uAuth = UserAuthentication.instance.helloWorld()
    // save the protect location, so we can redirect after login.
    localStorage.setItem('last_url', pathname);

    useEffect(() => {
        getApplicationStatus();

        // eslint-disable-next-line
    }, [auth]);

    // activate google analytics
    useEffect(() => {
        if (appData.plugin_data) {
            for (let index = 0; index < appData.plugin_data.length; index++) {
                const element = appData.plugin_data[index];
                if (element.plugin_key === "google_analytics") {
                    ReactGA.initialize(element.ga_property_id);
                    break;
                }
            };
        }

        // eslint-disable-next-line
    }, [appData]);

    const getApplicationStatus = () => {
        ApplicationStatus()
            .then(response => {
                setAppData(response.data, setLoadingData(false));
                setThemeData(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error?.status !== 403) {
                    // setConnectError(true);
                    // setLoadingData(false);
                }
            })
    };

    if (loadingData && loading && auth === undefined) {
        return (<Spinner />);
    } else {
        if (auth?.authenticated) {
            //check if setAuth is really a function
            typeof setAuth === "function" && setAuth(auth);
            return (

                <Layout className="">
                    <CustomCSS {...props} />
                    <Sider
                        // collapsed={false}
                        // reverseArrow={true}
                        collapsible={true}
                        breakpoint="md"
                        theme="light"
                        width="275">
                        <DashboardMenu
                            auth={auth}
                            history={history}
                            {...appData}
                            {...props} />
                    </Sider>

                    <Layout>
                        {/* header bar */}
                        <Header className="bg-white">
                            <HeaderBar
                                auth={auth} {...props}
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                                className="header bg-gradient" />
                        </Header>

                        {/* content section */}
                        <Content
                            className="site-layout-background ant-container-wrap"
                            style={{ minHeight: 'calc(100vh - 65px)', }}>
                            <div className="ant-main-content-wrapper">
                                {/* {children} */}
                                {React.Children.map(children, (child) => {
                                    return React.cloneElement(child, { ...props, ...appData, auth: auth });
                                })}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            );
        } else {
            // return empty element for protection
            return <div></div>;
        }
    };
};

export default ProtectedLayout;
