import React from "react";
import { Row, Col, Flex, } from 'antd';
import { FormattedMessage, } from "react-intl";
// import { Link, } from 'react-router-dom';

import ContactImage from '../../../assets/images/contact_image.jpg';
import { Clock, EnvelopeSimple, MapPin, PhoneCall } from "@phosphor-icons/react";

const ContactView = ({
    auth,
    history,
    ...props
}) => {

    const {
        branding_google_maps, branding_office_image, branding_phone,
        branding_location, branding_email, branding_kvk, branding_vat,
    } = props.themeData;

    return (

        <section className="contact__us__home" id="contact">
            <div className="auto_container mt-4">
                <div className="title">
                    <h2><FormattedMessage id="visit-contact" /></h2>
                </div>
                <div className="title__text">
                    <p><FormattedMessage id="visit-details" /></p>
                </div>

                <div className="row">
                    <div className="col-xl-5 col-lg-12 ">
                        <div className="contact__left">
                            <figure className="image">
                                <img
                                    src={branding_office_image ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_office_image}` : ContactImage}
                                    alt="" />
                            </figure>
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-12 ">
                        <div className="map__block">
                            <div className="google__map">
                                <iframe
                                    src={branding_google_maps || "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14599.578237069938!2d90.3654215!3d23.8223482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1680461233457!5m2!1sen!2sbd"}
                                    width="100%" height="516"
                                    title="Goole maps"
                                    allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <Row className="">

                                {branding_location && (
                                    <Col className="py-3" xl={12} md={12} xs={24}>
                                        <Row className="" align="middle">
                                            <Flex justify="center" align="center" gap="middle">
                                                <MapPin weight="light" size={45} />
                                                <h5>{branding_location?.location_name}</h5>
                                            </Flex>
                                        </Row>

                                        <div className="text__block">
                                            <p>{branding_location?.location_note}</p>
                                            {branding_kvk && (
                                                <p>KvK: {branding_kvk}</p>
                                            )}

                                            {branding_vat && (
                                                <p>BTW: {branding_vat}</p>
                                            )}
                                        </div>
                                    </Col>
                                )}

                                {branding_location?.location_show_opening_hours && (
                                    <Col className="py-3" xl={12} md={12} xs={24}>
                                        <Row className="" align="middle">
                                            <Flex justify="center" align="center" gap="middle">
                                                <Clock weight="light" size={45} />
                                                <h5><FormattedMessage id="our-openings-hours" /></h5>
                                            </Flex>
                                        </Row>

                                        <div className="text__block">
                                            <Row>
                                                {branding_location?.location_hours?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Col xl={8} md={6} xs={8}><p><FormattedMessage id={item.label} />:</p></Col>
                                                        <Col xl={16} md={18} xs={16}>{item.enable ? <p>{item.open} - {item.close}</p> : <p><FormattedMessage id="closed" /></p>}</Col>
                                                    </React.Fragment>
                                                ))}
                                            </Row>
                                        </div>
                                    </Col>
                                )}

                                {branding_phone && (
                                    <Col className="py-3" xl={12} md={12} xs={24}>
                                        <Row className="" align="middle">
                                            <Flex justify="center" align="center" gap="middle">
                                                <PhoneCall weight="light" size={45} />
                                                <h5><FormattedMessage id="phonenumber" /></h5>
                                            </Flex>
                                        </Row>
                                        <div className="text__block">
                                            <p className="">
                                                <a href={`tel:${branding_phone}`}>{branding_phone}</a> <br />
                                            </p>
                                        </div>
                                    </Col>
                                )}

                                {branding_email && (
                                    <Col className="py-3" xl={12} md={12} xs={24}>
                                        <Row className="" align="middle">
                                            <Flex justify="center" align="center" gap="middle">
                                                <EnvelopeSimple weight="light" size={45} />
                                                <h5><FormattedMessage id="emailaddress" /></h5>
                                            </Flex>
                                        </Row>

                                        <div className="text__block">
                                            <h5><FormattedMessage id="emailaddress" /></h5>
                                            <p className="">
                                                <a href={`mailto:${branding_email}`}>{branding_email}</a> <br />
                                            </p>
                                        </div>
                                    </Col>
                                )}

                            </Row>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};


export default ContactView;