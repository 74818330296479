import React, { useEffect, useState } from "react";
import { Form, Image, Row, Col, notification, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { Helmet, } from "react-helmet-async";
import { useFetchAuth } from './../../services/authentication/auth';
import { AccountActivationStatus } from './../../services/register';
import { CheckMagicLink, } from './../../services/users';
import LoginFormView from '../../components/account.modals/views/login.form.view';
import RequestPasswordFormView from '../../components/account.modals/views/request.password.form.view';
import ChangePasswordFormView from '../../components/account.modals/views/change.password.form';
import SignUpFormView from '../../components/account.modals/views/sign-up.form.view';
import ValidationPendingModal from '../../components/account.modals/views/validation.pending.modal';
import { LoadingSpinner } from '../../components/helpers';


const Logo = require(`../../assets/images/${process.env.REACT_APP_LOGO}`);

const LoginPage = ({
    setValue,
    ...props
}) => {

    const [authData, setAuthData] = useState(undefined);
    const [showForm, setShowForm] = useState("LOGIN");
    const [userEmail, setUserEmail] = useState();
    const [openValidationModal, setOpenValidationModal] = useState(false);
    const [loadingMagicLink, setLoadingMagicLink] = useState(true);
    const [loadingForm, setLoadingForm] = useState(true);
    const [sessionData, setSessionData] = useState(undefined);

    const { branding_slogan, branding_logo, branding_name, } = props.themeData;
    const { code, mail, token, } = useParams();
    const { pathname } = useLocation();
    const history = useNavigate();
    const intl = useIntl();
    const { auth, loading, } = useFetchAuth({ required: false });

    // first check if user wants to login using magic link
    // because older session could exist, distroy it frst
    useEffect(() => {
        if (token && mail) {

            //  Clear the local storage to make sure no old authentication exist.
            localStorage.removeItem('authentication_srs_shop');

            // validate magic link
            checkMagicLinkValidation(mail, token);
        } else {
            // otherwise check for active session
            setLoadingMagicLink(false);
            setLoadingForm(true);
            checkActiveUserSession();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mail, token, loading]);

    useEffect(() => {
        if (pathname.indexOf('activation') > 0) {
            if (code) { getValidationStatus(code); }
        }

        // eslint-disable-next-line
    }, [pathname, code]);

    useEffect(() => {
        if (auth) {
            history('/dashboard');
        }
        // eslint-disable-next-line
    }, [auth]);

    useEffect(() => {
        if (authData?.access_token) {
            history('/dashboard');
        }
        // eslint-disable-next-line
    }, [authData]);

    // set form available after the checks has finished
    useEffect(() => {
        if (!loading && !loadingMagicLink) {
            setLoadingForm(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loadingMagicLink]);

    const getValidationStatus = (code) => {
        // get the validation status
        AccountActivationStatus(code)
            .then(response => {
                if (response.status) {
                    setUserEmail(response.data.user_email);
                    setOpenValidationModal(true);
                } else {
                    notification['error']({
                        message: 'Account activation',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Account activation',
                    description: intl.formatMessage({ id: "ACCOUNT_ACTIVATION_NOT_POSSIBLE" }),
                });
            });
    };

    const loadForm = () => {
        switch (showForm) {
            case "LOGIN":
                return <LoginFormView
                    setShowForm={setShowForm}
                    setSessionData={setSessionData}
                    history={history}
                    setAuth={setAuthData}
                    setLoadingAccountData={() => { }}
                    modalIsOpenHandler={() => { }}
                    {...props} />
            case "REQUEST_PWD":
                return <RequestPasswordFormView
                    setShowForm={setShowForm}
                    history={history}
                    modalIsOpenHandler={() => { }}
                    {...props} />
            case "CHANGE_PWD":
                return <ChangePasswordFormView
                    setShowForm={setShowForm}
                    sessionData={sessionData}
                    setAuth={setAuthData}
                    history={history}
                    modalIsOpenHandler={() => { }}
                    {...props} />
            case "SIGN_UP":
                return <SignUpFormView
                    setShowForm={setShowForm}
                    sessionData={() => { }}
                    history={history}
                    modalIsOpenHandler={() => { }}
                    formType={showForm}
                    setValueParentCart={() => { }}
                    {...props}
                />
            default:
                return <LoginFormView
                    history={history}
                    modalIsOpenHandler={() => { }}
                    {...props} />
        };
    };

    const checkActiveUserSession = () => {
        if (!loading && authData?.authenticated) {
            history("/dashboard");

        }
    };

    const checkMagicLinkValidation = (mail, token) => {
        if (token && mail) {
            setLoadingMagicLink(true);

            const data = {
                user_email: mail,
                token: token,
            };

            CheckMagicLink(data).then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Inloggen',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                    localStorage.setItem('authentication_srs_shop', JSON.stringify(response.data));
                    history("/dashboard");

                } else {
                    notification['error']({
                        message: 'Inloggen',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setLoadingMagicLink(false);
                }

            }).catch(error => {
                setLoadingMagicLink(false);
            })
            // } else {
            //     setLoadingMagicLink(false);
        }
    };

    return (
        <div className="boxed_wrapper">
            <Helmet>
                <title>{branding_name}</title>
                <meta name="description" content={branding_slogan} />
                <meta property="og:title" content={branding_slogan} />
                {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
            </Helmet>

            <div className="success sec__pad">
                <div className="auto_container">

                    <div className="image__success p-4">
                        {/* <a href={`${process.env.REACT_APP_WEBSITE}/`}>  */}
                        <Image
                            style={{ maxWidth: 300 }}
                            preview={false}
                            // width={"400px"}
                            src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo}
                            alt="SRS Logo"
                            className="company-logo"
                        />
                        {/* </a> */}
                    </div>


                    <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                        size="large"
                        className="login-form mt-4">

                        <div className="login-content">
                            <p className="text-center h4">
                                <FormattedMessage id={`login_message` || "UNKNOWN"} /></p>

                            <Row align="middel" justify="center">
                                <Col xxl={10} xl={10} md={18} sm={18} xs={22}
                                    // offset={9}
                                    className="p-4"
                                    style={{ backgroundColor: "#e6e6e6" }}>

                                    {loadingForm ? (<LoadingSpinner />) : (loadForm())}
                                    {/* <LoginFormView
                                        // setShowForm={setShowForm}
                                        // setSessionData={setSessionData}
                                        history={history}
                                        setAuth=
                                        setLoadingAccountData={() => { }}
                                        modalIsOpenHandler={() => { }}
                                        {...props} /> */}

                                </Col>
                            </Row>
                        </div>
                    </Form>


                </div>
            </div>

            <ValidationPendingModal
                modalIsOpen={openValidationModal}
                modalIsOpenHandler={setOpenValidationModal}
                userEmail={userEmail}
                validationToken={code}
                // loading={loading}
                history={history}
            />

        </div>
    );
};

export default LoginPage;