import React, { useState, useEffect, Fragment, } from "react";
import { FormattedMessage, } from "react-intl";
import { useLocation, } from 'react-router-dom';
import Spinner from "../../../../components/Spinner";
import {
    Row, Card, Button,
    Col, Descriptions,
    notification, Dropdown, Tag,
} from 'antd';
import { StatusBadge, } from './../../../../components/helpers';
import { CustomerEditModal, EditUserModal } from '../users';
import { CustomerDetails } from '../../../../services/customer';
import { Bank, CaretDown, Pen, Trash, User, } from "@phosphor-icons/react";
import { COUNTRIES } from '../../../../constants/countries';  // default for Netherlands = nl

const CustomerDetailView = ({
    history,
    setCustomerName = () => { },
    ...props
}) => {

    const [userProfileData, setUserProfileData] = useState({});
    const [customerData, setcustomerData] = useState({});
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [permission, setPermission] = useState("users");
    const { pathname } = useLocation();

    // Modals
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [editUserOpen, setEditUserOpen] = useState(false);
    const [deleteCustomerOpenModal, setDeleteCustomerOpenModal] = useState(false);

    useEffect(() => {
        if ("authentication_srs_shop" in localStorage) {
            const localdata = localStorage.getItem('authentication_srs_shop');
            const idToken = JSON.parse(localdata);
            if ("permission" in idToken) {
                setPermission(idToken.permission);
            }
        }

        LoadCustomerDetails();

        return () => {
            // cleanup
            setUserProfileData({});
            setcustomerData({});
            setDetailsLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadCustomerDetails = () => {
        setDetailsLoading(true);

        // now get the company details
        CustomerDetails("me", history)
            .then(response => {
                if (response.status) {
                    setcustomerData(response.data);
                    setDetailsLoading(false);
                    setCustomerName(response.data.customer_name);
                }
                else {
                    history('/dashboard');
                    console.error('error:', response)
                    notification['warning']({
                        message: 'Customer details',
                        description: "Error on requesting Customer data",
                    });

                }
            })
            .catch(error => {
                console.error('error: ', error);
            });
    };

    const cardMenuItems = {
        items: [
            {
                key: '1',
                className: "mr-2",
                onClick: () => { setEditCustomerOpen(!editCustomerOpen) },
                icon: <Pen size={18} weight="light" className="mr-1" />,
                label: <FormattedMessage id="customer-edit" />,
            },
            {
                key: '2',
                hidden: permission === "users" || pathname === '/profile',
                className: "mr-2",
                danger: true,
                onClick: () => { setDeleteCustomerOpenModal(!deleteCustomerOpenModal) },
                icon: <Trash size={18} weight="light" className="mr-1" />,
                label: <FormattedMessage id="customer-delete" />,

            },
        ]
    };

    if (detailsLoading) return <Spinner />;

    return (
        <Fragment>

            <Row gutter={[32, 32]} className="">
                <Col span={24}>

                    {/* Customer detail card */}
                    <Card
                        title={<h3 className="card-title"><FormattedMessage id="customer-details" /></h3>}
                        className=""
                        extra={[
                            <Dropdown
                                key="1"
                                menu={cardMenuItems}
                                trigger={['click']}
                                className="pointer" >
                                <Button>
                                    <Pen size={18} weight="light" className="mr-1" /> Bewerken <CaretDown size={18} weight="light" />
                                </Button>
                            </Dropdown>
                        ]}>

                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={<FormattedMessage id="status" />}><StatusBadge status={customerData.customer_status} /></Descriptions.Item>
                            <Descriptions.Item label={<FormattedMessage id="type" />}>
                                <Tag color={customerData.customer_type === 'COMPANY' ? 'green' : 'geekblue'}>
                                    {customerData.customer_type === 'COMPANY' ? <Bank size={18} weight="light" className="mr-1" /> : <User size={18} weight="light" className="mr-1" />}
                                    {customerData.customer_type?.toUpperCase()}
                                </Tag>
                            </Descriptions.Item>

                            {customerData.customer_type === 'COMPANY' ? (
                                <>
                                    <Descriptions.Item label={<FormattedMessage id="company-name" />}>{customerData.customer_name}</Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="company-registration-number" />}>{customerData?.customer_data?.customer_registration_number}</Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="company-vat-number" />}>{customerData?.customer_data?.customer_vat_number}</Descriptions.Item>
                                </>
                            ) : (
                                <Descriptions.Item label={<FormattedMessage id="customer-name" />}>{customerData.customer_name}</Descriptions.Item>
                            )}
                        </Descriptions>
                    </Card>

                    {/* address details */}
                    <Card
                        title={<h3 className="card-title"><FormattedMessage id="customer-details-address" /></h3>}
                        className="mt-4">

                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={<FormattedMessage id="customer-street-number" />}>{customerData?.customer_data?.customer_address?.street_number}</Descriptions.Item>
                            <Descriptions.Item label={<FormattedMessage id="customer-zip-city" />}>{customerData?.customer_data?.customer_address?.zip_code} {customerData?.customer_data?.customer_address?.city}</Descriptions.Item>
                            <Descriptions.Item label={<FormattedMessage id="country" />}>
                                {COUNTRIES.find(item => item.code === (customerData?.customer_data?.customer_address?.country || 'nl'))?.name}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>

                    {/* common contact details */}
                    <Card
                        title={<h3 className="card-title"><FormattedMessage id="common-contact-details" /></h3>}
                        className="my-4">

                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={<FormattedMessage id="phonenumber" />}>{customerData?.customer_data?.customer_contact_data?.customer_phone_number}</Descriptions.Item>
                            <Descriptions.Item label={<FormattedMessage id="emailaddress" />}>{customerData?.customer_data?.customer_contact_data?.customer_email}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>

            {editUserOpen && (
                <EditUserModal
                    modalIsOpen={editUserOpen}
                    modalIsOpenHandler={setEditUserOpen}
                    userProfileData={userProfileData}
                    refreshData={LoadCustomerDetails}
                    history={history} />
            )}

            {editCustomerOpen && (
                <CustomerEditModal
                    modalIsOpen={editCustomerOpen}
                    modalIsOpenHandler={setEditCustomerOpen}
                    customerData={customerData}
                    refreshData={LoadCustomerDetails}
                    history={history} />
            )}


        </Fragment>
    );
};


export default CustomerDetailView;