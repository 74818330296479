import * as api from '../api';

export const UserSignout = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/sign_out', data)
            .then(response => {
                resolve(response.data);
                // remove also the localstorage items
                localStorage.removeItem("authentication_srs_shop");
            })
            .catch(error => {
                // console.error('error: ', error);
                reject(error);
            })
    });
};

export const UserStatus = () => {
    return new Promise((resolve, reject) => {
        api
            .get('/user/status')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.warn('No user session detected.');
                reject(error);
            })
    });
};

export const UserLogin = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/login', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const RequestPassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/request_password', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ConfirmRequestPassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/confirm_request_password', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ChangePassword = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/changepassword', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const ChangePasswordForced = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/changepassword_forced', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const UserUpdate = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/update', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const UserDelete = (data) => {
    return new Promise((resolve, reject) => {
        api
            .remove('/user/delete', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const UserAdd = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/add', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CheckUserExists = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/user_exists', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const UserDetails = (data) => {
    return new Promise((resolve, reject) => {
        api
            .get('/user/details/' + data.user_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const UserList = (customer_id) => {
    return new Promise((resolve, reject) => {
        api
            .get('/user/list/' + customer_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CheckMagicLink = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/user/check_magic_link', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const GetUserMFAToken = () => {
    return new Promise((resolve, reject) => {
        api
            .get('/user/mfa/generate_mfa_code')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const EnableUserMFA = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/mfa/enable', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const DisableUserMFA = (data) => {
    return new Promise((resolve, reject) => {
        api
            .put('/user/mfa/disable', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};