import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Spinner from "../../../../components/Spinner";
import { StatusBadge } from '../../../../components/helpers';
import { useForm, Controller } from "react-hook-form";

import { Row, Card, Input, Button, Col, notification, Form } from 'antd';

import { ChangePassword, GetUserMFAToken, EnableUserMFA, UserDetails } from '../../../../services/users';
import { DisableUserMFAModal } from '../users';
import { QRCodeSVG } from 'qrcode.react';
import { CheckSquare, Power, XSquare } from "@phosphor-icons/react";

const ProfileSecurityView = ({
    history,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [mfaDataLoading, setMfaDataLoading] = useState(false);
    const [mfaDataLoaded, setMfaDataLoaded] = useState(false);
    const [mfaData, setMfaData] = useState(undefined);
    const [disableMFAModalOpen, setDisableMFAModalOpen] = useState(false);
    const [userProfileData, setUserProfileData] = useState({});
    const [detailsLoading, setDetailsLoading] = useState(true);

    const changePasswordForm = useForm({ mode: "all" });
    const enableMFAForm = useForm({ mode: "all" });
    const intl = useIntl();
    const customerID = "me";

    useEffect(() => {
        LoadProfileDetails();

        return () => {
            // cleanup
            setUserProfileData({});
            setDetailsLoading(true);
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadProfileDetails = () => {
        setDetailsLoading(true);

        // get the user profile details
        UserDetails({ user_id: customerID })
            .then(response => {
                if (response.status) {
                    setUserProfileData(response.data);
                    setDetailsLoading(false);
                } else {
                    history('/dashboard');
                    console.error('error:', response)
                    notification['warning']({
                        message: 'User details',
                        description: "Error on requesting profile settings data",
                    });

                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                }
            });
    };

    const savePassword = (data) => {

        setButtonDisabled(true);

        ChangePassword(data)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'User password',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    changePasswordForm.reset({ current_password: "", new_password: "", confirm_new_password: "" });

                } else {
                    notification['error']({
                        message: 'User password',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                }
                setButtonDisabled(false);

            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'User password',
                    description: intl.formatMessage({ id: error.msg }),
                });

                setButtonDisabled(false);
            });

    };

    const startEnableMFA = () => {
        setMfaDataLoading(true);
        GetUserMFAToken()
            .then(response => {
                if (response.status) {
                    setMfaData(response.data);
                    setMfaDataLoaded(true);
                } else {
                    console.error('error: ', response);
                }
                setMfaDataLoading(false);
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'User authentication',
                    description: "An error occurred. Please check the browser console for more information",
                });
            });
    };

    const disableMFA = () => {
        setDisableMFAModalOpen(true);
    };

    const enableMFA = (data) => {
        setMfaDataLoading(true);
        setButtonDisabled(true);
        EnableUserMFA(data)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'User MFA',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                    LoadProfileDetails();
                    setMfaDataLoaded(false);
                    setMfaData(undefined);
                    setMfaDataLoading(false);
                    setButtonDisabled(false);

                } else {
                    notification['error']({
                        message: 'User MFA',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setMfaDataLoading(false);
                    setButtonDisabled(false);
                }

            })
    };

    return (
        <React.Fragment>
            {detailsLoading ? (<Spinner />) : (

                <Row gutter={[32, 32]}>
                    <Col span={24}>

                        {/* Change password card */}
                        <Card
                            title={<h3 className="card-title"><FormattedMessage id="changepassword" /></h3>}
                            className=""
                            loading={detailsLoading}>

                            <Form
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}>

                                {/* Current password */}
                                <FormattedMessage id="current-password">
                                    {NameField =>
                                        <Controller
                                            control={changePasswordForm.control}
                                            defaultValue=""
                                            name="current_password"
                                            rules={{
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" },
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { error },
                                            }) => (
                                                <Form.Item
                                                    help={error && intl.formatMessage({ id: error?.message })}
                                                    hasFeedback
                                                    validateStatus={error?.message && "error"}
                                                    required
                                                    label={NameField}>
                                                    <Input
                                                        placeholder={NameField}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="password"
                                                        autoComplete="off"
                                                    />
                                                </Form.Item>
                                            )}
                                        />}
                                </FormattedMessage>

                                {/* new password */}
                                <FormattedMessage id="newpassword">
                                    {NameField =>
                                        <Controller
                                            control={changePasswordForm.control}
                                            defaultValue=""
                                            name="new_password"
                                            rules={{
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" },
                                                pattern: {
                                                    value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                                                    message: "PasswordMustContainsNumbersLetters",
                                                },
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { error },
                                            }) => (
                                                <Form.Item
                                                    help={error && intl.formatMessage({ id: error?.message })}
                                                    hasFeedback
                                                    validateStatus={error?.message && "error"}
                                                    required
                                                    label={NameField}>
                                                    <Input
                                                        placeholder={NameField}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="password"
                                                        autoComplete="off"
                                                    />
                                                </Form.Item>
                                            )}
                                        />}
                                </FormattedMessage>

                                {/* repeat password */}
                                <FormattedMessage id="repeatpassword">
                                    {NameField =>
                                        <Controller
                                            control={changePasswordForm.control}
                                            defaultValue=""
                                            name="confirm_new_password"
                                            rules={{
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                minLength: { value: 8, message: "inputfield-minLength8" },
                                                pattern: {
                                                    value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                                                    message: "PasswordMustContainsNumbersLetters",
                                                },
                                                validate: {
                                                    compare: value => {
                                                        if (changePasswordForm.watch('new_password') !== value) {
                                                            return 'PasswordsNotEqual';
                                                        } else {
                                                            return undefined;
                                                        };
                                                    },
                                                }
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { error },
                                            }) => (
                                                <Form.Item
                                                    help={error && intl.formatMessage({ id: error?.message })}
                                                    hasFeedback
                                                    validateStatus={error?.message && "error"}
                                                    required
                                                    label={NameField}>
                                                    <Input
                                                        placeholder={NameField}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="password"
                                                        autoComplete="off"
                                                    />
                                                </Form.Item>
                                            )}
                                        />}
                                </FormattedMessage>

                                <Form.Item
                                    wrapperCol={{ span: 0, offset: 8, }}>
                                    <Button
                                        type="primary"
                                        disabled={buttonDisabled}
                                        loading={buttonDisabled}
                                        icon={<CheckSquare size={18} weight="light" className="mr-1" />}
                                        className="ant-btn-success"
                                        onClick={changePasswordForm.handleSubmit(savePassword)}
                                    >
                                        <FormattedMessage id="save-password" />
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>

                        {/* Profile MFA setup */}
                        <Card
                            title={<h3 className="card-title"><FormattedMessage id="profile-mfa-settings" /></h3>}
                            className="mt-4">

                            {/* MFA status */}
                            <Form
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}>

                                {/* show status */}
                                <Form.Item label={<FormattedMessage id="profile-mfa-status" />}>
                                    {
                                        userProfileData.user_mfa_enabled ?
                                            (<StatusBadge icon={<CheckSquare size={18} weight="light" />} status="ACTIVE" />) :
                                            (<StatusBadge icon={<XSquare size={18} weight="light" />} status="DISABLED" />)
                                    }
                                </Form.Item>

                                {/* show spinner on loading QR-Code */}
                                {!mfaDataLoaded && mfaDataLoading && (<Spinner />)}

                                {mfaDataLoaded && (
                                    <React.Fragment>
                                        <Form.Item label={<FormattedMessage id="scan-qr-code" />}>
                                            <QRCodeSVG value={mfaData.qrcode} />
                                            <p><FormattedMessage id="scan-qr-code-help" /></p>
                                        </Form.Item>

                                        {/* authenticator response code */}
                                        <FormattedMessage id="profile-mfa-authentication-token">
                                            {NameField =>
                                                <Controller
                                                    control={enableMFAForm.control}
                                                    defaultValue=""
                                                    name="user_code"
                                                    rules={{
                                                        required: { value: true, message: "inputfield-FieldIsRequired" },
                                                        minLength: { value: 6, message: "inputfield-FieldLength-6number" },
                                                        maxLength: { value: 6, message: "inputfield-FieldLength-6number" },
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref },
                                                        fieldState: { error },
                                                    }) => (
                                                        <Form.Item
                                                            help={error && intl.formatMessage({ id: error?.message })}
                                                            hasFeedback
                                                            validateStatus={error?.message && "error"}
                                                            required
                                                            label={NameField}>
                                                            <Input
                                                                placeholder={NameField}
                                                                onChange={onChange}
                                                                value={value}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Item>
                                                    )}
                                                />}
                                        </FormattedMessage>
                                    </React.Fragment>
                                )}


                                {/* show buttons, depending on the mfa status */}
                                <Form.Item
                                    className="text-center"
                                    wrapperCol={{ span: 20, offset: 2, }}
                                >

                                    {!userProfileData.user_mfa_enabled ? (

                                        <>
                                            {mfaDataLoaded ? (
                                                // enable mfa
                                                <Button
                                                    disabled={buttonDisabled}
                                                    loading={buttonDisabled}
                                                    onClick={enableMFAForm.handleSubmit(enableMFA)}
                                                    icon={<CheckSquare size={18} weight="light" className="mr-1" />}
                                                    className="ant-btn-success">
                                                    <FormattedMessage id="mfa-activate" />
                                                </Button>
                                            ) : (
                                                // disabled, show enable button
                                                <Button
                                                    disabled={buttonDisabled}
                                                    loading={buttonDisabled}
                                                    onClick={() => { startEnableMFA() }}
                                                    icon={<Power size={18} weight="light" className="mr-1" />}
                                                    className="ant-btn-success">
                                                    <FormattedMessage id="mfa-enable" />
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        // show disable button
                                        <Button
                                            type="primary"
                                            disabled={buttonDisabled}
                                            loading={buttonDisabled}
                                            icon={<XSquare size={18} weight="light" className="mr-1" />}
                                            danger
                                            onClick={() => disableMFA()}>
                                            <FormattedMessage id="mfa-disable" />
                                        </Button>
                                    )}
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>


            )}

            {disableMFAModalOpen && (
                <DisableUserMFAModal
                    modalIsOpen={disableMFAModalOpen}
                    modalIsOpenHandler={setDisableMFAModalOpen}
                    refreshData={LoadProfileDetails}
                    userID="me"
                    history={history}
                />
            )}
        </React.Fragment>
    );
};


export default ProfileSecurityView;