import React, { useState, useEffect, } from "react";
import { FormattedMessage, } from "react-intl";
import { Link, } from 'react-router-dom';
import { Row, Col, Button, } from 'antd';
import { GetCategories, GetOptions, GetWebshopCategories, } from '../../../services/products';
import { CategoryCard, } from '../../../cointainers/category';
import { XCircle, SquaresFour, ArrowRight, } from "@phosphor-icons/react";

const FeatureList = ({
    auth,
    history,
    ...props
}) => {

    const [categoryList, setCategoryList] = useState([]);
    const [options, setOptions] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const { branding_webshop_disable_rental, } = props.themeData;

    useEffect(() => {
        getOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (branding_webshop_disable_rental) {
            getWebshopCategories();
        } else {
            getCategories();
        }

        // eslint-disable-next-line
    }, [branding_webshop_disable_rental]);

    const getCategories = () => {
        setLoadingData(true);
        GetCategories(history).then(response => {

            if (response.status) {
                setCategoryList(response.data.categories);
            }
            setLoadingData(false);
        })
    };


    const getWebshopCategories = () => {
        setLoadingData(true);
        GetWebshopCategories(history).then(response => {

            if (response.status) {
                setCategoryList(response.data.categories);
            }
            setLoadingData(false);
        })
    };

    // Get the options, to determine the possible mandatory options for the category
    const getOptions = () => {
        GetOptions(history).then(response => {
            if (response.status) {
                setOptions(response.data.options);
            }
        });
    };

    return (

        <section className="product__section">
            <div className="auto_container">
                <div className="title">
                    <h2><FormattedMessage id="product-categories" /></h2>
                </div>
                <div className="title__text">
                    <div>
                        <p>
                            <FormattedMessage id="product-categories-info" /><br />
                            <Link to={branding_webshop_disable_rental ? `/webshop/products` : `/products`}><FormattedMessage id="product-categories-more" /> <ArrowRight /></Link>
                        </p>
                    </div>

                </div>

                {loadingData ? (
                    <>loading...</>
                ) : (

                    <div className="row justify-content-center">
                        {categoryList.slice(0, 3).map((item, index) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                <CategoryCard
                                    history={history}
                                    data={item}
                                    options={options}
                                    {...props} />
                            </div>
                        ))}
                    </div>
                )}

                {!loadingData && categoryList.length === 0 && (
                    <Row align="middle" justify="space-around"
                        className="text-not-found text-center">
                        <Col span={24} className="header">
                            <XCircle size={16} weight="light" /> <span><FormattedMessage id="product-categories-none" /></span>
                        </Col>

                        <Col span={24}>
                            <FormattedMessage id="product-categories-none-info" /><br />
                            <Link to={branding_webshop_disable_rental ? `/webshop/products` : `/products`}>
                                <Button className="mt-2">
                                    <SquaresFour size={18} weight="light" /> <FormattedMessage id="go-to-products" />
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                )}

            </div>
        </section>

    );
};


export default FeatureList;