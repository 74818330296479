import React from "react";
// import { FormattedMessage, } from "react-intl";
// import { FormatEuro, } from '../../../components/helpers';
import PlaceHolder from '../../../assets/images/placeholder.png';
import truncate from '../../../components/helpers/truncate.html';
import { FormattedMessage } from "react-intl";

const CategoryCard = ({
    data,
    options = [],
    history,
    ...props
}) => {


    // const [possibleOptions, setPossibleOptions] = useState([]);
    const { language, } = props;
    const { branding_webshop_disable_rental, } = props.themeData;

    // useEffect(() => {
    //     if (options.length > 0) {
    //         getPossibleOptions();
    //     }

    //     // eslint-disable-next-line
    // }, [options]);

    const goToProducts = (data) => {
        history(branding_webshop_disable_rental ? `/webshop/products` : `/products`, { state: { cat_id: data } });
    };

    // const getLowestPrice = (data) => {
    //     let mandatory_prices = [];

    //     for (let index = 0; index < possibleOptions.length; index++) {
    //         const element = possibleOptions[index];
    //         if (element.product_pricing.option_mandatory) {
    //             mandatory_prices = element.product_pricing.product_pricing.map(item => item.price);
    //         }
    //     };

    //     const prices = data.map(item => item.price);
    //     const lowestPrice = prices.reduce((min, price) => Math.min(min, price), prices[0]);
    //     if (mandatory_prices.length === 0) {
    //         return lowestPrice
    //     } else {
    //         return lowestPrice + mandatory_prices.reduce((min, price) => Math.min(min, price), mandatory_prices[0]);
    //     }
    // };

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    // const getPossibleOptions = () => {
    //     // get only the posible options (get from product category cat_options)
    //     var possible_options = [];

    //     data?.cat_options.map((item, index) => {
    //         // Get the option from the options array
    //         var option = options.find((element) => {
    //             return element.product_id === item && element.product_enabled;
    //         });

    //         if (option) {
    //             // option.parent_product_data = productData;
    //             possible_options.push(option);
    //         }
    //         return true;
    //     });
    //     // setPossibleOptions(possible_options);
    // };

    return (

        <div className="shop__content d-block pointer" onClick={e => { goToProducts(data.cat_id) }}>

            <div className="shop__top">
                <div className="inner__box">

                    <figure className="image__box">
                        <img src={data.cat_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.cat_images[0].image_s3_key}` : PlaceHolder} alt="" />
                    </figure>

                    <div className="lower-content">
                        <div className="product__icon">
                        </div>
                    </div>
                </div>
            </div>

            <div className="shop__bottom">
                <div className="product__content">
                    <h5>{data?.cat_names?.[`cat_name_${language}`] || data?.cat_names?.[`cat_name_nl`] || data?.cat_name}</h5>
                    {renderHTMLContent(
                        truncate(
                            data.cat_descriptions?.[`cat_description_${language}`] || data.cat_descriptions?.cat_description_nl || data.cat_description, 125))}
                </div>
                <div className="mt-1 text-lowercase">
                    {/* <h5>
                        <span><FormattedMessage id="from" /> <FormatEuro amount={getLowestPrice(data.cat_pricing)} /> <FormattedMessage id="per-day" /></span>
                    </h5> */}

                    {/* <span>€96,00</span> <span className="discount">€105,00</span> */}
                </div>

                <div className="product__addtocart">
                    <button className="btn-one small"><span className="txt"> <FormattedMessage id="view-category" /></span></button>
                </div>
            </div>
        </div>

    );
};

export default CategoryCard;