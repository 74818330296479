import React from "react";
import { DatePicker, } from 'antd';
import dayjs from 'dayjs';
import antdLocale from 'antd/es/date-picker/locale/nl_NL';

const DateRangeSelector = ({
    selectedDate = [],
    setSelectedDate,
    ...props
}) => {

    const { themeData } = props;
    const { branding_location, settings_holidays, branding_financial } = themeData;

    const dateSelector = (dates, dateStrings) => {
        const [startDate, endDate] = dates;
        const startDateJS = startDate.toDate();
        const endDateJS = endDate.toDate();
        setSelectedDate([startDateJS, endDateJS]);
    };

    const disabledDate = (current) => {
        // check if selected day has been closed.
        const allowSameDayOrder = branding_financial?.same_day_order;
        const checkAllowSameDayOrder = allowSameDayOrder ? (current < dayjs().endOf('day').add(-1, 'day')) : (current < dayjs().endOf('day'));
        const isSelectedDayDisabled = !(branding_location?.location_hours.find(day => day.label === dayjs(current).format('dddd').toLowerCase())?.enable);
        const isSelectedDayHoliday = settings_holidays.find(day => dayjs(day.holiday_date || '1970-01-01').format('YYYY-MM-DD') === dayjs(current).format('YYYY-MM-DD'))?.holiday_closed || false;

        // Check the location_hours / location_hour_type
        if (branding_location?.location_hour_type === "OPEN") {
            return current && (checkAllowSameDayOrder || isSelectedDayHoliday);
        } else {
            return current && (checkAllowSameDayOrder || isSelectedDayDisabled || isSelectedDayHoliday);
        }
    };

    return (
        <DatePicker.RangePicker
            style={{ width: "100%" }}
            locale={antdLocale}
            size="large"
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            placement="bottomLeft"
            // allowEmpty={[true, true]}
            onChange={dateSelector}
            value={selectedDate.length === 2 ? [dayjs(selectedDate[0]), dayjs(selectedDate[1])] : [null, null]}
            className="mb-2" />
    );
};

export default DateRangeSelector;
