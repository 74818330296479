import React, { useEffect, useState, } from "react";
import { Modal, Button, Row, Col, List, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { getRentalPeriod, } from '../../../components/helpers';
import dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import { nl, } from 'date-fns/locale';
import { CheckSquare, } from "@phosphor-icons/react";

import 'react-datepicker/dist/react-datepicker.css';
import './timeslot.css';


const ProductTimeSlotSelectorModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    timeslots,
    selectedTime,
    setSelectedTime,
    selectedDate,
    setSelectedDate,
    ...props
}) => {

    const [rentalPeriod, setRentalPeriod] = useState(undefined);
    const [showTimeSelector, setShowTimeSelector] = useState(true);
    const [times, setTimes] = useState([]);

    useEffect(() => {
        if (rentalPeriod) {
            generateTimes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalPeriod]);

    useEffect(() => {
        setShowTimeSelector(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedDate.length === 2) {
            const tmp = getRentalPeriod(selectedDate, timeslots);
            setRentalPeriod(tmp);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    // Generate time slots. Take in account the number of hours the user wants to rent the boat
    const generateTimes = () => {
        const result = [];
        const startHour = parseInt(rentalPeriod.ts_interval_start_time?.split(':')[0]);
        const endHour = parseInt(rentalPeriod.ts_interval_end_time?.split(':')[0]) - (parseInt(1));
        for (let i = startHour; i <= endHour; i += rentalPeriod.ts_interval_amount) {
            const time = i.toString().padStart(2, '0') + ':00';
            result.push(time);
        }
        if (result.length > 0) {
            setTimes(result);
            setShowTimeSelector(true);
        }
    };


    return (

        <Modal
            open={modalIsOpen}
            closable={false}
            maskClosable={false}
            // width={650}
            footer={[
                // <Button onClick={e => modalIsOpenHandler(false)} key="close"><X size={16} className="mr-1" /><FormattedMessage id="close" /></Button>,
                <Button
                    className="ant-btn-success"
                    onClick={e => { modalIsOpenHandler(false) }}
                    key="save"><CheckSquare size={16} className="mr-1" /><FormattedMessage id="save" /></Button>
            ]}
            onCancel={() => modalIsOpenHandler(false)}>

            <TimeSlotSelector
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                timeslots={timeslots}
                {...props}
            />

            {showTimeSelector && (
                <Row>
                    <Col span={24} className="p-4">

                        <List
                            header={<div><h4><FormattedMessage id="choose-start-time" /></h4></div>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={times}
                            renderItem={(item) => (
                                <List.Item
                                    className={`time-slot ${item === selectedTime ? 'time-slot-selected' : ''} `}
                                    onClick={e => setSelectedTime(item)}>
                                    {item}
                                </List.Item>
                            )}
                        />

                    </Col>
                </Row>
            )}
        </Modal>
    );
};

export default ProductTimeSlotSelectorModal;


// https://reactdatepicker.com/#example-filter-dates
const TimeSlotSelector = ({
    selectedDate,
    setSelectedDate,
    timeslots,
    ...props
}) => {

    const { themeData } = props;
    const { branding_location, settings_holidays, branding_financial } = themeData;

    const disabledDate = (current) => {
        // check if selected day has been closed.
        const allowSameDayOrder = branding_financial?.same_day_order;
        const checkAllowSameDayOrder = allowSameDayOrder ? (current < dayjs().endOf('day').add(-1, 'day')) : (current < dayjs().endOf('day'));
        const isSelectedDayDisabled = !(branding_location?.location_hours.find(day => day.label === dayjs(current).format('dddd').toLowerCase())?.enable);
        const isSelectedDayHoliday = settings_holidays.find(day => dayjs(day.holiday_date || '1970-01-01').format('YYYY-MM-DD') === dayjs(current).format('YYYY-MM-DD'))?.holiday_closed || false;

        // Check the location_hours / location_hour_type
        if (branding_location?.location_hour_type === "OPEN") {
            return !(current && (checkAllowSameDayOrder || isSelectedDayHoliday));
        } else {
            return !(current && (checkAllowSameDayOrder || isSelectedDayDisabled || isSelectedDayHoliday));
        }
    };

    return (
        <div className="custom__booking__system">
            <DatePicker
                selected={selectedDate[0]}
                className="booking__system"
                onChange={(date) => { setSelectedDate([date, date]) }}
                // showTimeSelect={showTimeSelector}
                filterDate={disabledDate}
                // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5), addDays(new Date(), 15), addDays(new Date(), 18), addDays(new Date(), 25)]}
                // filterTime={filterPassedTime}
                // dateFormat="dd MMM yyyy HH:mm"
                dateFormat="dd MMM yyyy"

                // minTime={minTime}
                // maxTime={maxTime}
                // minDate={addDays(new Date(), 1)}
                minDate={new Date()}
                // timeIntervals={interval}
                // timeClassName={handleColor}
                inline
                locale={nl}
                timeCaption={<FormattedMessage id="choose-time" />}
            // disabled={true}
            />
        </div>



    );
};
