import * as api from '../api';


export const Register = (data) => {
    return new Promise((resolve, reject) => {
        // let formData = new FormData();
        // formData.append("file", data);
        // formData.append("file_type", file_type);

        api
            .post('/registration/new', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const CompanySearch = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/registration/company_search', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const AccountActivationStatus = (validation_token) => {
    return new Promise((resolve, reject) => {
        api
            .get('/registration/registration_status/' + validation_token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const RegisterCustomer = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/registration/new_customer', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const AccountActivateService = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/registration/activate', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};
