import React, { useEffect, useState, } from "react";
import { Layout, notification, } from 'antd';
import { useNavigate, useParams, useLocation, } from 'react-router-dom';
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

import { PublicLayout } from '../../components/layouts';
import { AccountActivationStatus } from '../../services/register';
import HomePageHero from './views/hero';
import HomeHeaderBar from '../../components/header/header';
import PageFooter from '../../components/footer/footer';
import FeatureList from './views/feature.list';
import ContactView from './views/contact.view';

import ValidationPendingModal from '../account.modals/views/validation.pending.modal';
import AccountLoginModal from '../account.modals/account.login.modal';
const Logo = require(`./../../assets/images/${process.env.REACT_APP_LOGO}`);

// import PitchSection from './views/pitch';
// import HomePagePricing from './views/pricing';

const { Content } = Layout;

const HomePage = ({
    ...props

}) => {

    const [userEmail, setUserEmail] = useState();
    const [openValidationModal, setOpenValidationModal] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [, setAuth] = useState(undefined);        // authentication info will retrive from headerbar
    const { branding_name, branding_logo, branding_slogan, } = props.themeData;
    const [defaultLoginForm, setDefaultLoginForm] = useState("LOGIN");

    const history = useNavigate();
    const { code } = useParams();
    const { pathname } = useLocation();
    const intl = useIntl();

    useEffect(() => {

        if (pathname.indexOf('activation') > 0) {
            if (code) { getValidationStatus(code); }
        }

        if (pathname.indexOf('login') > 0) {
            setOpenLoginModal(true);
        }

        if (pathname.indexOf('registration') > 0) {
            setDefaultLoginForm("SIGN_UP");
            setOpenLoginModal(true);
        }

        if (pathname.indexOf('onsite_registration') > 0) {
            setDefaultLoginForm("SIGN_UP_ONSITE");
            setOpenLoginModal(true);
        }

        // eslint-disable-next-line
    }, [pathname, code]);

    const getValidationStatus = (code) => {
        // get the validation status
        AccountActivationStatus(code)
            .then(response => {
                if (response.status) {
                    setUserEmail(response.data.user_email);
                    setOpenValidationModal(true);
                } else {
                    notification['error']({
                        message: 'Account activation',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Account activation',
                    description: intl.formatMessage({ id: "ACCOUNT_ACTIVATION_NOT_POSSIBLE" }),
                });
            });
    };

    return (
        <PublicLayout className=" bg-gray-01" {...props}>
            <Helmet>
                <title>{branding_name}</title>
                <meta name="description" content={branding_slogan} />
                <meta property="og:title" content={branding_slogan} />
                {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
            </Helmet>

            <HomeHeaderBar {...props} history={history} setAuth={setAuth} />

            <Content>

                <HomePageHero {...props} />

                <FeatureList {...props} history={history} />

                <ContactView {...props} />

                {openLoginModal && (
                    <AccountLoginModal
                        history={history}
                        setAuth={setAuth}
                        modalIsOpen={openLoginModal}
                        modalIsOpenHandler={setOpenLoginModal}
                        defaultForm={defaultLoginForm}
                        {...props}
                    />
                )}

            </Content>

            <PageFooter {...props} />

            <ValidationPendingModal
                modalIsOpen={openValidationModal}
                modalIsOpenHandler={setOpenValidationModal}
                userEmail={userEmail}
                validationToken={code}
                // loading={loading}
                history={history}
            />

        </PublicLayout>
    );
};

export default HomePage;