import React from "react";
import { Switch, Image, Row, Col, } from 'antd';
import { FormattedMessage } from "react-intl";
import { FormatEuro, getRentalPeriod, } from '../../../components/helpers';
import truncate from '../../../components/helpers/truncate.html';
import PlaceHolder from '../../../assets/images/placeholder.png';

const ProductOptionListCard = ({
    readOnly = true,        // If this card is used fo display only, set to true
    data,
    setOptionSelection,
    selectedOptions,        // this give an array containing the selected options (needed to have the mandatory options checked by default)
    available,
    availableChecked,
    totalDays,
    selectedDate,
    ...props
}) => {

    const { language } = props;
    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <Row>
            <div className="option-card-container">
                <div className="option-card-content">
                    {data.product_pricing.option_mandatory && (
                        <h4
                            className="text-danger text-center"
                            style={{}}>Verplichte optie</h4>
                    )}
                    <div className="option-card-title">{data?.product_names?.[`product_name_${language}`] || data?.product_names?.[`product_name_nl`] || data?.product_name}</div>
                    <div className="option-card-image text-center">
                        <Image
                            style={{
                                objectFit: "cover",
                                // width: "80px",
                                height: "80px"
                            }}
                            src={data.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.product_images[0].image_s3_key}` : PlaceHolder}
                        />
                    </div>
                    <div className="option-card-content">
                        {renderHTMLContent(truncate(data.product_descriptions?.[`product_description_${language}`] || data.product_descriptions?.product_description_nl || data.product_description, 200))}
                    </div>
                </div>

                <div className="option-card-pricing">
                    <Row>
                        <Col lg={{ span: 18 }} xs={{ span: 18 }}>
                            {data.product_pricing.product_one_time_item ? (
                                <div><FormattedMessage id="one-time" />: <FormatEuro amount={data.product_pricing?.product_pricing[0]?.price || 0} /></div>
                            ) : (
                                <React.Fragment>
                                    {selectedDate?.length === 2 && getRentalPeriod(selectedDate, data.product_pricing.product_time_slots)?.ts_interval_pricing?.map((item, index) => (
                                        <div key={index}>
                                            {item.interval_amount === 1 && (<div><small><FormattedMessage id="price-per-day" />: <FormatEuro amount={item.interval_price} /></small></div>)}
                                            {item.interval_amount > 1 && (<div><small><FormattedMessage id="price-on" /> {item.interval_amount} <FormattedMessage id="days" />: <FormatEuro amount={item.interval_price} /></small></div>)}
                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </Col>

                        {!readOnly && (
                            <Col lg={{ span: 5 }}
                                xs={{ span: 5 }}
                                className="text-end">
                                <Switch
                                    checked={selectedOptions?.find(item => item.product_id === data.product_id)?.enable || data.product_pricing.option_mandatory}
                                    disabled={!availableChecked || !available || data.product_pricing.option_mandatory}
                                    onChange={status => { setOptionSelection(status, data) }}
                                />
                            </Col>
                        )}
                    </Row>

                </div>
            </div>
        </Row>

    );
};


export default ProductOptionListCard;