import * as api from '../api';



export const GetProfileStatus = () => {
    return new Promise((resolve, reject) => {
        api
            .get(`/profile/status`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const GetProfilePayments = () => {
    return new Promise((resolve, reject) => {
        api
            .get(`/profile/payments`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const GetDashboardCounter = () => {
    return new Promise((resolve, reject) => {
        api
            .get(`/profile/dashboard_counters`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};



export const ProfileImageUpload = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/profile/image_upload`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};



// export const ProfileImageUpload = (data, file_type) => {
//     return new Promise((resolve, reject) => {
//         let formData = new FormData();
//         formData.append("file", data);
//         formData.append("file_type", file_type);

//         api
//             .post('/profile/image_upload', formData,
//                 {
//                     headers: { "Content-Type": "multipart/form-data", },
//                 }
//             )
//             .then(response => {
//                 resolve(response.data);
//             })
//             .catch(error => {
//                 reject(error);
//             })
//     });
// };