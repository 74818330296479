import React, { useState, } from "react";
import { Modal, Result, notification, Form, Input } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { useForm, Controller } from "react-hook-form";

import { LoadingOutlined, } from '@ant-design/icons';
import { AccountActivateService, } from '../../../services/register';
import { LockSimple } from "@phosphor-icons/react";

const ValidationPendingModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    userEmail,
    validationToken,
    history,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationDone, setValidationDone] = useState(false);
    const intl = useIntl();

    const { handleSubmit, control, watch, } = useForm(
        { mode: "all" }, // set the field validation mode
    );

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setLoading(true);

        // set data for services
        const postdata = {
            validation_token: validationToken,
            new_password: data.new_password,
            confirm_new_password: data.confirm_new_password,
            user_email: userEmail,
        }

        AccountActivateService(postdata)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Account activate',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    history('/');
                    setValidationDone(true);
                    setLoading(false);

                } else {
                    setButtonDisabled(false);
                    notification['error']({
                        message: 'Account activate',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                };
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Account activate',
                    description: intl.formatMessage({ id: "ACCOUNT_ACTIVATION_NOT_POSSIBLE" }),
                });
                setButtonDisabled(false);
            });
    };

    return (
        <Modal
            open={modalIsOpen}
            closable={false}
            maskClosable={false}
            width={650}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            {loading && (
                <>
                    <div className="text-center">
                        <h4><FormattedMessage id="account-activate-pending" /></h4>
                    </div>
                    <div className="spinner-container">
                        <LoadingOutlined />
                    </div>
                </>
            )}

            {!loading && (
                <Result
                    className="result-background"
                    status={validationDone ? "success" : "info"}
                    title={<FormattedMessage id={validationDone ? "account-activate-done" : "account-activate-last-step"} />}
                    subTitle={<FormattedMessage id={validationDone ? "account-activate-done-info" : "account-activate-last-step-info"} />} //"Vul hieronder een wachtwoord in om je account te activeren, je kan dan meteen inloggen."
                    extra={[
                        //   <Button type="primary" key="console">
                        //     Go Console
                        //   </Button>,
                        //   <Button key="buy">Buy Again</Button>,
                    ]}>

                    {validationDone ? (
                        <div className="text-center">
                            <div className="btn__box"
                                onClick={e => { history('/login'); modalIsOpenHandler(false) }}>
                                <button className="btn-one pointer">
                                    <span className="txt text-center">
                                        <FormattedMessage id="login-now" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="vertical"
                            size="large"
                            className="login-form mt-4">

                            {/* new password field */}
                            <FormattedMessage id="password">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="new_password"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                            minLength: { value: 8, message: "inputfield-minLength8" }
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                label={<strong>{NameField}</strong>}
                                                required>
                                                <Input.Password
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* repeat password field */}
                            <FormattedMessage id="repeatpassword">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="confirm_new_password"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                            minLength: { value: 8, message: "inputfield-minLength8" },
                                            validate: {
                                                compare: value => {
                                                    if (watch('new_password') !== value) {
                                                        return 'PasswordsNotEqual';
                                                    } else {
                                                        return undefined;
                                                    };
                                                },
                                            }
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                required
                                                label={<strong>{NameField}</strong>}>
                                                <Input.Password
                                                    placeholder={NameField}
                                                    onChange={onChange}
                                                    value={value}
                                                    autoComplete="off"
                                                    prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                                />
                                            </Form.Item>
                                        )}
                                    />}
                            </FormattedMessage>

                            <div className="text-center mt-4">
                                <div className="btn__box"
                                    onClick={handleSubmit(onSubmit)}>
                                    <button className="btn-one pointer mt-4" disabled={buttonDisabled}>
                                        <span className="txt text-center ">
                                            {buttonDisabled ? (
                                                <LoadingOutlined />) : (
                                                <FormattedMessage id="save-activate-account" />
                                            )}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Result>
            )}
        </Modal>
    );
};

export default ValidationPendingModal;
