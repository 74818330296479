import React, { useEffect, useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { Row, Col, Tabs, } from 'antd';
import { useNavigate, useParams, } from "react-router-dom";
import { Helmet, } from "react-helmet-async";

import { ProtectedLayout, } from './../../../../components/layouts';
import { ProfileDetailView, ProfileSecurityView, } from '../index';
import DocumentsGridView from '../documents/document.gridview';
import { UsersView, } from '../users'
import { useLocation, } from "react-router-dom";
import { FileText, ShieldCheck, User, UsersThree, } from "@phosphor-icons/react";

const Logo = require(`../../../../assets/images/${process.env.REACT_APP_LOGO}`);

const ProfileDetailPage = ({
    ...props

}) => {
    const [pagetab, setPagetab] = useState();
    const { tab } = useParams();
    const { pathname } = useLocation();
    const { themeData: { branding_slogan, branding_logo, branding_name, permission_template, } = {} } = props;
    const { system_document_manager } = permission_template || {};

    const history = useNavigate();

    useEffect(() => {
        setPagetab(tab);
        if (pathname.indexOf('/linkedin_connect') > 0) {
            setPagetab("linkedin");
        }
        // eslint-disable-next-line
    }, [tab]);

    const tabItems = [
        {
            label: <><User size={22} weight="light" /> <FormattedMessage id="profile-myinfo" /></>,
            key: "detail",
            children: <ProfileDetailView history={history} {...props} />
        },
        {
            label: <><ShieldCheck size={22} weight="light" /> <FormattedMessage id="profile-security" /></>,
            key: "security",
            children: <ProfileSecurityView history={history} />
        },
        {
            label: <><UsersThree size={22} weight="light" /> <FormattedMessage id="profile-myusers" /></>,
            key: "users",
            children: <UsersView history={history} />
        },
        system_document_manager &&
        {
            label: <><FileText size={22} weight="light" /> <FormattedMessage id="profile-my-files" /></>,
            key: "documents",
            children: <DocumentsGridView history={history} customerID="me" />
        },
    ];

    return (
        <ProtectedLayout {...props} history={history}>
            <Helmet>
                <title>{branding_name}</title>
                <meta name="description" content={branding_slogan} />
                <meta property="og:title" content={branding_slogan} />
                {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
            </Helmet>

            <Row gutter={[32, 32]} className="my-2">

                {/* Tab container */}
                <Col span={24} className="">

                    {/* Tabs selctions */}
                    <Tabs defaultActiveKey="detail"

                        activeKey={pagetab}
                        items={tabItems}
                        onChange={e => history('/profile/' + e)} />

                </Col>
            </Row>

        </ProtectedLayout>
    );
};

export default ProfileDetailPage;