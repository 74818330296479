import React, { useEffect, useState, } from "react";
import { DatePicker, Form, List, notification, } from 'antd';
import { useLocation, } from 'react-router-dom';
import { FormattedMessage, useIntl, } from "react-intl";
import { FormatEuro, calculateTotal, optionPricing, } from '../../../components/helpers';
import ProductGotoShoppingcartModal from '../detail.views/product.goto.shoppingcart.modal';
import ProductOptionListCard from '../views/product.option.list.card';
import antdLocale from 'antd/es/date-picker/locale/nl_NL';
// import dayjs from 'dayjs';

const ProductSideBarAdvancedView = ({
    data,
    selectedCategory,
    options,
    history,
    ...props
}) => {

    const [modalIsOpen, modalIsOpenHandler] = useState(false);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [productAmount, setProductAmount] = useState(data.product_min_order_quantity);
    const [productTotalPrice, setProductTotalPrice] = useState(0);

    const { triggerShoppingCart, themeData, language, } = props;
    const { branding_location, branding_financial, } = themeData;
    const { state } = useLocation();
    const intl = useIntl();

    useEffect(() => {
        if (state?.date_range) {
            setSelectedDate(state.date_range);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (productAmount < 1) { setProductAmount(1) }
        // eslint-disable-next-line
    }, [productAmount]);

    // trigger calculateTotal
    useEffect(() => {

        const record = {
            product_category: selectedCategory,
            selected_options: selectedOptions,
            possible_options: options,
            date_range: selectedDate,
            days_free_of_charge: [],
            bookdetail_amount: 1,
            product_data: data,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
        };

        const results = calculateTotal(record);
        setProductTotalPrice(results.total_price);

        // eslint-disable-next-line
    }, [selectedDate, productAmount, selectedOptions, selectedCategory]);

    // set the mandatory options
    useEffect(() => {
        if (options) {
            for (let index = 0; index < options.length; index++) {
                const element = options[index];
                if (element.product_pricing.option_mandatory) {
                    setOptionSelection(true, element);
                }
            }
        }
        // eslint-disable-next-line
    }, [options]);

    const setOptionSelection = (status, item) => {
        // const daysFreeOfCharge = branding_location.location_hours.filter(day => !day.enable && !day.charge_when_closed);

        // Check if currect option is already in array selectedOptions
        // const existing_option = selectedOptions.find((option) => { return option.product_id === item.product_id }) || [];
        const option_index = selectedOptions.findIndex((option) => option.product_id === item.product_id);

        const record = {
            date_range: selectedDate,
            days_free_of_charge: [],
            bookdetail_amount: productAmount,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
            option_data: item,
            parent_product_data: data,
        };

        const results = optionPricing(record);
        const new_item = {
            label: item.product_names?.[`product_name_${language}`] || item.product_names?.product_name_nl || item.product_name,
            price: results.price_per_interval,
            product_one_time_item: item.product_pricing.product_one_time_item,
            enable: status,
            product_id: item.product_id,
            product_vat_group: item?.product_pricing?.product_vat_group,
            bookdetail_amount: 1,
            option_data: item,
        };

        let newState = [...selectedOptions];

        // existing_option
        if (option_index === -1) {
            newState.push(new_item);
        } else {
            newState[option_index] = new_item;
        }

        setSelectedOptions(newState);
    };

    const addToShoppingcart = () => {

        if (!selectedDate) {
            return false;
        }

        if (productAmount < data.product_min_order_quantity) {
            notification['warning']({
                message: intl.formatMessage({ id: "order-to-small" }),
                description: intl.formatMessage({ id: "order-to-small-info" }),
            });
            return false;
        }

        const daysFreeOfCharge = branding_location?.location_hours.filter(day => !day.enable && !day.charge_when_closed);
        const shoppingcart = {
            product_data: data,
            product_category: selectedCategory,
            bookdetail_amount: productAmount,
            date_range: selectedDate,
            days_free_of_charge: daysFreeOfCharge,
            possible_options: options,
            selected_options: selectedOptions,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
        };

        triggerShoppingCart(shoppingcart);
        modalIsOpenHandler(true);
    };

    const dateSelector = (dates, dateStrings) => {
        setSelectedDate(dateStrings);
    };

    return (

        <div className="">
            {data && (<>
                <h4 className="hover-primary mt-2">
                    <FormattedMessage id="book-now" /> {data.product_names?.[`product_name_${language}`] || data.product_name}</h4>

                <div className="">
                    <div className="">

                        {/* Select date */}
                        <div className="form-group mb-2 mt-2">
                            <Form.Item label={<FormattedMessage id="choose-start-date" />}>
                                <DatePicker
                                    size="large"
                                    style={{ width: "100%" }}
                                    locale={antdLocale}
                                    // selectedDate={selectedDate}
                                    onChange={dateSelector}
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </div>

                        {/* Show options  */}
                        {options.length > 0 && (
                            <>
                                <hr />
                                <h5 className="mb-2"><FormattedMessage id="optional-on-this-product" /></h5>

                                <List
                                    grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2, }}
                                    itemLayout="horizontal"
                                    className="mt-4"
                                    dataSource={options}
                                    rowKey={item => item?.product_id}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={index}>
                                            <ProductOptionListCard
                                                readOnly={false}
                                                data={item}
                                                setOptionSelection={setOptionSelection}
                                                selectedOptions={selectedOptions}
                                                available={selectedDate}
                                                availableChecked={selectedDate}
                                                totalDays={1}
                                                selectedDate={selectedDate}
                                                {...props} />
                                        </List.Item>
                                    )}
                                />
                            </>
                        )}
                    </div>
                </div>

                <div className=" mb-2 text-center">
                    <h4><FormattedMessage id="total" /> <FormatEuro amount={productTotalPrice} /></h4>
                </div>

                <div className="text-center">
                    {!selectedDate && (
                        <div className="text-danger"><small><FormattedMessage id="first-choose-date" /></small></div>
                    )}

                    {data.product_min_order_quantity > 1 && (
                        <div className="mt-2 text-danger">LET OP: Dit product heeft een minimale afnamen van: {data.product_min_order_quantity}</div>
                    )}
                </div>

                <div className="other__options__add mt-4">
                    <div className="item__quantity">
                        {/* Amount */}
                        <button type="button"><span onClick={e => setProductAmount(productAmount - 1)}>-</span>
                            {productAmount}
                            <span onClick={e => setProductAmount(productAmount + 1)}>+</span></button>
                    </div>
                    <div className="btn__box">
                        <button type="button"
                            disabled={!selectedDate}
                            onClick={e => addToShoppingcart()} className="btn__two"><FormattedMessage id="add-to-cart" />
                        </button>
                    </div>
                </div>
            </>)}

            <ProductGotoShoppingcartModal
                modalIsOpen={modalIsOpen}
                modalIsOpenHandler={modalIsOpenHandler}
                history={history}
            />
        </div>
    );
};

export default ProductSideBarAdvancedView;