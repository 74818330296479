import React, { useState, useEffect, } from "react";
import { Link, useLocation, } from 'react-router-dom';
import { FormattedMessage, } from "react-intl";
import { Select, Form, Col, Row, } from 'antd';
import { GetCategories, } from '../../services/products';
import { DateRangeSelector } from '../../components/helpers';

const SearchBannerForm = ({
    auth,
    history,
    copyCategories = () => { },
    ...props
}) => {

    const [categories, setCategories] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const { state } = useLocation();
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState([]);
    const { language, branding_webshop_disable_rental } = props;

    useEffect(() => {
        getCategories();

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // setup the search field
    useEffect(() => {
        if (state?.date_range) {
            setSelectedCategory(state.cat_id);
            setSelectedDate(state.date_range);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const getCategories = () => {
        setLoadingData(true);
        GetCategories(history).then(response => {
            if (response.status) {
                setCategories(response.data.categories, setLoadingData(false));
                copyCategories(response.data.categories);
            }
        });
    };

    return (
        <React.Fragment>
            <div className="form__title">
                <h5><FormattedMessage id="choos-date-to-rent" /></h5>
            </div>

            <Form className="property-search position-relative" layout="vertical">
                <Row
                    align="middle" justify="space-around"
                    className="row align-items-center ml-lg-0 py-lg-0 shadow-sm-2 rounded bg-white position-lg-absolute top-lg-n50px py-lg-0 px-3 z-index-1 w-md-100">

                    {/* Date selector */}
                    <Col className="order-1" md={8} sm={24}>
                        <Form.Item
                            className="text-uppercase font-weight-500 letter-spacing-093"
                            label={<FormattedMessage id="select-date" />}>
                            <DateRangeSelector
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                {...props}
                            />

                        </Form.Item>
                    </Col>

                    {/* Categorie selector */}
                    <Col className="order-2" md={8} sm={24}>
                        <Form.Item
                            className="text-uppercase font-weight-500 letter-spacing-093"
                            label={<FormattedMessage id="select-category" />}>
                            <Select size="large" className="mb-2"
                                defaultValue=""
                                value={selectedCategory}
                                loading={loadingData}
                                disabled={loadingData}
                                onChange={(e) => { setSelectedCategory(e) }}>
                                <Select.Option value=""><FormattedMessage id="make-a-choice" /></Select.Option>
                                {categories.map(item => (
                                    <Select.Option key={item.cat_id} value={item.cat_id}>
                                        {item?.cat_names?.[`cat_name_${language}`] || item?.cat_names?.[`cat_name_nl`] || item?.cat_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* <div className="position-relative">
                                <input type="text" name="search" className="form-control bg-transparent shadow-none border-top-0 border-right-0 border-left-0 border-bottom rounded-0 h-24 lh-17 pl-0 pr-4 font-weight-600 border-color-input placeholder-muted" placeholder="Find something..." />
                                <i className="far fa-search position-absolute pos-fixed-right-center pr-0 fs-18 mt-n3"></i>
                            </div> */}
                    </Col>

                    {/* <div className="form-group">
                        <button className="btn-one" type="submit" name="submit-form"><span className="txt">Search Now</span></button>
                    </div> */}

                    <Col className="order-5 text-center" md={8} sm={24}>
                        <Link to={branding_webshop_disable_rental ? `/webshop/products` : `/products`} state={{ cat_id: selectedCategory, date_range: selectedDate }}>
                            <button className="btn-one">
                                <span className="txt"> <FormattedMessage id="search" /></span></button>
                            {/* <Button
                                size="large"
                                type="primary"
                                className="btn-one">
                                <SearchOutlined /> <FormattedMessage id="search" />
                            </Button> */}
                        </Link>
                    </Col>

                </Row>
            </Form>
        </React.Fragment>
    );
};


export default SearchBannerForm;