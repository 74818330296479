import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";
import { FormatEuro, getLowestPrice, } from '../../../components/helpers';
import PlaceHolder from '../../../assets/images/placeholder.png';
import { FormattedMessage } from "react-intl";

const ProductCard = ({
    auth,
    history,
    data,
    state,
    selectedCategory,
    options = [],
    ...props
}) => {

    const [possibleOptions, setPossibleOptions] = useState([]);
    const { language } = props;

    useEffect(() => {
        if (options.length > 0) {
            getPossibleOptions();
        }

        // eslint-disable-next-line
    }, [options]);


    const getPossibleOptions = () => {
        // get only the posible options (get from product category cat_options)
        var possible_options = [];

        selectedCategory?.cat_options.map((item, index) => {
            // Get the option from the options array
            var option = options.find((element) => {
                return element.product_id === item && element.product_enabled;
            });

            if (option) {
                // option.parent_product_data = productData;
                possible_options.push(option);
            }
            return true;
        });
        setPossibleOptions(possible_options);
    };

    const removeHTMLTags = (str) => {
        return str?.replace(/<[^>]*>?/gm, '').substring(0, 80);
    };


    return (

        <Link
            to={`/product/${data.product_id}`}
            state={{ cat_id: data.product_category, date_range: state?.date_range }}>

            <div className="shop__content d-block">
                <div className="shop__top">
                    <div className="inner__box">
                        <figure className="image__box"
                        // style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <img src={data.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.product_images[0].image_s3_key}` : PlaceHolder}
                                // style={{ objectFit: "scale-down", height: "300px" }}
                                alt="rental product" />
                        </figure>

                        {new Date().addDays(-21) < new Date(data.product_created) && (
                            <div className="product__title">
                                <span><FormattedMessage id="new" /></span>
                            </div>
                        )}

                        <div className="lower-content">
                            <div className="product__icon">
                                <ul>
                                    {/* <li><div><i className="flaticon-shopping-cart"></i></div></li>
                                    <li><div><i className="flaticon-heart"></i></div></li>
                                    <li><div><i className="flaticon-search-interface-symbol"></i></div></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="shop__bottom">
                    <div className="product__content">
                        <h5><div>{data.product_names?.[`product_name_${language}`] || data.product_names?.product_name_nl || data.product_name} </div></h5>
                        {removeHTMLTags(data.product_descriptions?.[`product_description_${language}`] || data.product_descriptions?.product_description_nl || data.product_description)}


                        {/* <div className="product__price">
                            <span className="text-lowercase"><FormattedMessage id="from" />
                                <FormatEuro amount={getLowestPrice(data, possibleOptions)} className="mx-1" />
                                <FormattedMessage id={`per-${data.product_pricing?.product_time_slots[0]?.ts_interval_type.toLowerCase()}`} />
                            </span>
                            <span className="discount">€105,00</span>
                        </div> */}
                    </div>


                    <div className="product__addtocart">
                        {/* Price container */}
                        <span className="text-lowercase"><FormattedMessage id="from" />
                            <FormatEuro amount={getLowestPrice(data, possibleOptions)} className="mx-1" />
                            <FormattedMessage id={`per-${data.product_pricing?.product_time_slots[0]?.ts_interval_type.toLowerCase()}`} />
                        </span>
                        <br />

                        {/* Fake button */}
                        <button className="btn-one small mt-1"><span className="txt"> <FormattedMessage id="rent-now" /></span></button>
                    </div>

                </div>
            </div>

        </Link>
    );
};


export default ProductCard;