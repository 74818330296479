import React, { useState, useEffect, } from "react";
import { Layout, notification, } from 'antd';
import { useLocation, useParams, useNavigate, Link, } from 'react-router-dom';
import { FormattedMessage, useIntl, } from "react-intl";
import { Helmet, } from "react-helmet-async";

import { PublicLayout } from '../../components/layouts';
import { LoadingSpinner } from '../../components/helpers';

import HomeHeaderBar from '../../components/header/header';
import PageFooter from '../../components/footer/footer';

// # Product standard views
import ProductDetailStandardPage from './details.standard/product.detail.standard.view';

// product advanced views
import ProductDetailAdvancedPage from "./details.advanced/product.detail.advanced.view";

// product webshop views
import ProductDetailWebshopPage from './details.webshop/product.detail.webshop.view';

import { GetProductDetails, GetOptions, GetCategories, GetWebshopCategories, } from '../../services/products';
import PageHeaderBackground from '../../assets/images/page-title.png';

const Logo = require(`../../assets/images/${process.env.REACT_APP_LOGO}`);

const { Content } = Layout;

const ProductDetailPage = ({
    ...props

}) => {
    const {
        branding_color_header, branding_page_image, branding_webshop_disable_rental,
        branding_slogan, branding_logo, branding_name,
    } = props.themeData;
    const { language, } = props;
    const [loadingData, setLoadingData] = useState(true);
    const [productData, setProductData] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [options, setOptions] = useState([]);
    const { state } = useLocation();
    const { product_id } = useParams();
    const intl = useIntl();
    const history = useNavigate();

    useEffect(() => {
        if (categories && productData) {
            setLoadingData(false);
        }
    }, [categories, productData]);

    // (A) getting product details and categories
    useEffect(() => {
        getProductDetails();
        getCategories();

        // eslint-disable-next-line
    }, []);

    // (B) getting the selected category
    useEffect(() => {
        if (categories?.length > 0 && productData) {
            const cat = categories.find((item) => { return item.cat_id === productData?.product_category });
            setSelectedCategory(cat);
        }

        // eslint-disable-next-line
    }, [categories, productData]);

    // (C) getting the options (options are linked to the categories.)
    useEffect(() => {
        if (selectedCategory) {
            getOptions();
        }
        // eslint-disable-next-line
    }, [selectedCategory]);

    // (1) first getting the product details
    const getProductDetails = () => {
        GetProductDetails(product_id, history).then(response => {
            if (response.status) {
                setProductData(response.data.product);
            } else {
                notification['error']({
                    message: 'Product details',
                    description: intl.formatMessage({ id: response.msg }),
                });
                history("/");
            }
        })
    };

    // (2) second get the categories
    const getCategories = () => {
        var tmp_cats = [];
        GetCategories(history).then(response => {
            if (response.status) {
                tmp_cats = response.data.categories;
                GetWebshopCategories(history).then(response => {
                    if (response.status) {
                        setCategories([...tmp_cats, ...response.data.categories]);
                    }
                });
            }
        });
    };

    // (3) third get the options (can only run if the categories are loaded)
    const getOptions = () => {

        GetOptions(history).then(response => {
            if (response.status) {

                // get only the posible options (get from product category cat_options)
                var possible_options = [];

                selectedCategory?.cat_options.map((item, index) => {
                    var option = response.data.options.find((element) => {
                        return element.product_id === item && element.product_enabled;
                    });

                    if (option) {
                        // if product advanced, only the one time items are possible
                        if (productData?.product_type === "PRODUCT_ADVANCED" && option.product_pricing.product_one_time_item) {
                            option.parent_product_data = productData;
                            possible_options.push(option);
                        }

                        if (productData?.product_type === "PRODUCT") {
                            option.parent_product_data = productData;
                            possible_options.push(option);
                        }
                    }
                    return true;
                });

                setOptions(possible_options);
            }
        });
    };

    return (
        <PublicLayout className="bg-gray-01" {...props} >
            <Helmet>
                <title>{branding_name}</title>
                <meta name="description" content={branding_slogan} />
                <meta property="og:title" content={branding_slogan} />
                {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
            </Helmet>
            <HomeHeaderBar {...props} history={history} />

            {(loadingData) ?
                (
                    <Content style={{ height: "45vh", marginTop: "200px" }}>
                        <LoadingSpinner />
                    </Content>
                ) : (
                    <Content>

                        {/* Page header */}
                        <div className="page__title p_relative"
                            style={{
                                backgroundImage: branding_page_image ? `url('https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_page_image}')` : `url(${PageHeaderBackground})`,
                                backgroundColor: `${branding_color_header}`
                            }}
                        // style={{ backgroundImage: `url(${PageHeaderBackground})` }}
                        >
                            <div className="auto_container text-center">
                                <div className="content-box p_relative">
                                    <ul className="bread-crumb">
                                        <li><Link to="/">Home </Link></li>
                                        <li> /</li>
                                        <li><Link to={branding_webshop_disable_rental ? `/webshop/products` : `/products`} state={{ cat_id: productData?.product_category, date_range: state?.date_range }}>
                                            <FormattedMessage id="products" /></Link></li>
                                        <li> /</li>
                                        <li><FormattedMessage id="product-details" /></li>
                                    </ul>
                                    <h1 className="title">{productData?.product_names?.[`product_name_${language}`] || productData?.product_name}</h1>

                                </div>
                            </div>
                        </div>

                        {/* Product detail section */}
                        {productData.product_type === "PRODUCT_ADVANCED" && (
                            <ProductDetailAdvancedPage
                                productData={productData}
                                selectedCategory={selectedCategory}
                                options={options}
                                history={history}
                                {...props}
                            />
                        )}

                        {productData.product_type === "PRODUCT" && (
                            <ProductDetailStandardPage
                                productData={productData}
                                selectedCategory={selectedCategory}
                                options={options}
                                history={history}
                                {...props}
                            />
                        )}

                        {productData.product_type === "SHOP" && (
                            <ProductDetailWebshopPage
                                productData={productData}
                                selectedCategory={selectedCategory}
                                options={options}
                                history={history}
                                {...props}
                            />
                        )}

                    </Content>
                )}
            <PageFooter />
        </PublicLayout>
    );
};

export default ProductDetailPage;