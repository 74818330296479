
import { Avatar, } from 'antd';
import Gravatar from 'react-gravatar';

const ProfileImage = ({
    image = undefined,
    email,
    size,

}) => {

    return (
        <>
            {image ? (
                <Avatar
                    size={size || 50}
                    src={image}
                />
            ) : (
                <Avatar
                    size={size || 50}
                    icon={<Gravatar
                        email={email || ''}
                        // default="monsterid"
                        className="rounded-full"
                        // default={`/images/user-thin.png`}
                        default={`${process.env.REACT_APP_ADMIN}/images/user-thin.png`}
                    />}
                />
            )}
        </>
    );
};

export default ProfileImage;