import * as api from '../api';

export const GetLocations = (history) => {
    return new Promise((resolve, reject) => {
        api
            .get('/public/locations')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetCategories = (history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/public/categories`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};


export const GetWebshopCategories = (history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/public/categories/webshop`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetOptions = (history) => {
    return new Promise((resolve, reject) => {
        api
            .get('/public/options')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetProducts = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post('/public/products', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetwebshopProducts = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post('/public/products/webshop', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetProductDetails = (id, history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/public/product/details/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetProductAvailability = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post('/public/product/availability', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};
