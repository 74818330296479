import React, { useState, useEffect, } from "react";
import { Layout, Row, Col, Button, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { useLocation, useNavigate, } from 'react-router-dom';
import { Helmet, } from "react-helmet-async";

import { GetProducts, GetCategories, GetOptions, } from '../../../services/products';
import { PublicLayout } from '../../../components/layouts';

import HomeHeaderBar from '../../../components/header/header';
import PageFooter from '../../../components/footer/footer';
import ProductCard from './product.card';
import ProductCardLoading from './product.card.loading'
import SearchBannerForm from '../../../components/search';

import PageHeaderBackground from '../../../assets/images/page-title.png';
import { SquaresFour, XCircle } from "@phosphor-icons/react";

const Logo = require(`../../../assets/images/${process.env.REACT_APP_LOGO}`);
const { Content } = Layout;

const ProductListView = ({
    ...props

}) => {

    const { branding_color_header, branding_page_image, branding_slogan, branding_logo, branding_name, } = props.themeData;
    const [productList, setProductList] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const { state } = useLocation();
    const history = useNavigate();

    useEffect(() => {
        getProducts();
        getOptions();

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const getProducts = () => {
        setLoadingData(true);
        const post_data = {
            cat_id: state?.cat_id,
            date_range: state?.date_range,
        };

        GetProducts(post_data, history).then(response => {
            setProductList(response.data.products, getCategories());
        });
    };

    const getCategories = () => {
        GetCategories(history).then(response => {
            if (response.status) {
                setCategories(response.data.categories, setLoadingData(false));
            } else {
                setLoadingData(false);
            }
        });
    };

    // Get the options, to determine the possible mandatory options for the category
    const getOptions = () => {
        GetOptions(history).then(response => {
            if (response.status) {
                setOptions(response.data.options);
            }
        });
    };

    return (
        <PublicLayout className="" {...props}>
            <Helmet>
                <title>{branding_name}</title>
                <meta name="description" content={branding_slogan} />
                <meta property="og:title" content={branding_slogan} />
                {/* <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/ict-vacatures`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                <link rel="icon" type="image/png" href={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo} sizes="16x16" />
            </Helmet>

            <HomeHeaderBar {...props} history={history} />

            <Content>

                <section>
                    <div className="page__title p_relative"
                        style={{
                            backgroundImage: branding_page_image ? `url('https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_page_image}')` : `url(${PageHeaderBackground})`,
                            backgroundColor: `${branding_color_header}`
                        }}
                    // style={{ backgroundImage: `url(${PageHeaderBackground})` }}
                    >
                        <div className="auto_container text-center">
                            <div className="content-box p_relative">
                                <ul className="bread-crumb">
                                    <li><a href="/">Home </a></li>
                                    <li> /</li>
                                    <li><FormattedMessage id="products" /></li>
                                </ul>
                                <h1 className="title"><FormattedMessage id="rental-overview" /></h1>
                            </div>
                        </div>
                    </div>

                    <div className="product__form">
                        <div className="auto_container">
                            <div className="product__from__content">
                                <SearchBannerForm {...props} />
                            </div>
                        </div>
                    </div>

                    <div className="product__section">
                        <div className="auto_container">

                            <div className="product__top__bar">

                                {/* Filter options */}

                                {/* <div className="filter__option">
                                    <ul>
                                        <li><i className="flaticon-filter"></i></li>
                                        <li><span>Filter</span></li>
                                        <li><i className="flaticon-grid"></i></li>
                                        <li><i className="flaticon-list"></i></li>
                                    </ul>
                                </div> */}

                            </div>

                            <div className="row justify-content-center">

                                {loadingData ? (
                                    <>
                                        {/* If state is loading data. */}
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                            <ProductCardLoading />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" style={{ opacity: "0.6" }}>
                                            <ProductCardLoading />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" style={{ opacity: "0.3" }}>
                                            <ProductCardLoading />
                                        </div>
                                    </>
                                ) : (<>
                                    <React.Fragment>
                                        {productList.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {!item?.product_pricing?.advanced_pricing && (
                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                                                        key={index}>
                                                        <ProductCard
                                                            data={item}
                                                            options={options}
                                                            {...props}
                                                            state={state}
                                                            selectedCategory={categories.find((cat) => { return cat.cat_id === item.product_category })}
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                </>)}

                                {!loadingData && productList.length === 0 && (
                                    <Row align="middle"
                                        justify="space-around"
                                        className="text-not-found text-center">
                                        <Col span={24}>
                                            <XCircle size={18} weight="light" className="mr-1" /> <FormattedMessage id="products-listing-none" /> <br /><FormattedMessage id="products-listing-none-info" /><br />
                                            <br />
                                            <Button href="/products" className="mt-2">
                                                <SquaresFour size={18} weight="light" className="mr-1" /> <FormattedMessage id="go-to-categories" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}

                                <div className="pagenation">

                                    {/* Pageination options */}

                                    {/* <div className="show__page">
                                        <p>Page 1 of 10</p>
                                    </div>
                                    <div className="next__button">
                                        <div className="button-01">
                                            <a href="#">Previous</a>
                                        </div>
                                        <div className="button-01">
                                            <a href="#">Next</a>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Content>
            <PageFooter />

        </PublicLayout>
    );
};

export default ProductListView;