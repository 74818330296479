import React, { useState } from "react";
import { Image, } from 'antd';
import PlaceHolder from '../../../assets/images/placeholder.png';

const ProductPhotoView = ({
    data,
    history,
    ...props
}) => {

    const [visible, setVisible] = useState(false);

    return (

        <Image.PreviewGroup
            preview={{
                visible: visible,
                // current: 4,
                onVisibleChange: (value) => {
                    setVisible(value);
                },
            }}>

            <div className="">

                {/* On top large image */}
                <div className="image__list__rigth">
                    <figure className="image">
                        <Image
                            className=""
                            // style={{
                            //     objectFit: "scale-up",
                            //     height: "500px"
                            // }}
                            src={data[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data[0].image_s3_key}` : PlaceHolder} />
                    </figure>
                </div>

                {/* Image list below */}
                <div className="product__thumbnails mt-4">
                    <ul>
                        {data.map((item, index) => (
                            <React.Fragment key={item.image_id}>
                                {index > 0 && index < 4 ? (
                                    <li className="">
                                        <Image
                                            style={{ objectFit: "cover", height: "110px" }}
                                            className=""
                                            src={`https://${process.env.REACT_APP_AWS_DATASTORE}/${data[index].image_s3_key}`} />
                                    </li>
                                ) : (
                                    <>
                                        {index === 4 && (
                                            <li className="">
                                                <div className="p-0">
                                                    <Image
                                                        style={{ objectFit: "cover", height: "110px" }}
                                                        className=""
                                                        src={data[index] ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data[index].image_s3_key}` : PlaceHolder} />
                                                    {/* <div
                                                        onClick={e => { setVisible(true) }}
                                                        className="pointer card-img-overlay d-flex flex-column align-items-center justify-content-center hover-image bg-dark-opacity-04">
                                                        <p className="fs-48 font-weight-600 text-white lh-1 mb-4">+{data.length - 5}</p>
                                                        <p className="fs-16 font-weight-bold text-white lh-1625 text-uppercase">Bekijk meer</p>
                                                    </div> */}
                                                </div>
                                            </li>
                                        )}

                                        {/* Hidden images, showing only in preview mode. */}
                                        {index > 4 && (
                                            <Image
                                                rootClassName="d-none"
                                                className=""
                                                src={data[index] ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data[index].image_s3_key}` : PlaceHolder}
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}

                    </ul>
                </div>
            </div>

        </Image.PreviewGroup>

    );
};

export default ProductPhotoView;