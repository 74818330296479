import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Card, notification, Select, } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { UserUpdate } from '../../../../../services/users';
import { CheckSquare, Pen, X } from "@phosphor-icons/react";

const EditUserModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    userProfileData,
    refreshData,

}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { handleSubmit, reset, control } = useForm();
    const intl = useIntl();
    const history = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        // setup correct data object
        const formdata = {
            "user_email": userProfileData.user_email,
            "user_phone_number": userProfileData.user_data.user_phone_number,
            "user_first_name": userProfileData.user_data.user_first_name,
            "user_last_name": userProfileData.user_data.user_last_name,
            "customer_id": userProfileData.customer_id,
            "user_language": userProfileData?.user_data?.user_language,
        };
        reset(formdata);

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileData, pathname])

    const onSubmit = (data) => {
        setButtonDisabled(true);
        const postdata = {
            "user_id": userProfileData.user_id,
            "user_first_name": data.user_first_name,
            "user_last_name": data.user_last_name,
            "user_phone_number": data.user_phone_number,
            "customer_id": userProfileData.customer_id,
            "user_language": data.user_language,
            // TODO need to change the mail address: "user_email":data.user_email,
        };

        // check if the optional mail address has entered
        // if (data.contact_email) {
        //     postdata.customer_data = { ...postdata.customer_data, "contact_email": data.contact_email }
        // }

        UserUpdate(postdata)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Inloggen',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    // history('/customer/' + response.data.customer_id);
                    //close modal
                    modalIsOpenHandler(false);
                    refreshData();
                } else {
                    notification['error']({
                        message: 'User update',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                    setButtonDisabled(false);
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                } else {
                    notification['error']({
                        message: 'Profiel update',
                        description: "Error loading customer data. Please refresh browser and try again!",
                    });
                    setButtonDisabled(false);
                }
            });
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><Pen size={18} weight="light" className="mr-1" /> <FormattedMessage id="profile-user-details" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            className="user"
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></Button >,
                <Button key="2"
                    className="ant-btn-success"
                    loading={buttonDisabled}
                    onClick={handleSubmit(onSubmit)}>
                    {!buttonDisabled && (<CheckSquare size={18} weight="light" className="mr-1" />)} <FormattedMessage id="save" /></Button>,
            ]}>

            {/* user basic */}
            <Card sizee="small" title={<FormattedMessage id="profile-user-details-info" />}>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >

                    {/* Contact person (first & last name) */}
                    <Form.Item
                        label={<FormattedMessage id="firstname-and-lastname" />}
                        required
                        style={{ marginBottom: 0 }}>
                        <FormattedMessage id="first-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_first_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />
                            }
                        </FormattedMessage>

                        <FormattedMessage id="last-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_last_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />
                            }
                        </FormattedMessage>
                    </Form.Item>

                    {/* phone number */}
                    <FormattedMessage id="phonenumber">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_phone_number"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={NameField}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* email address */}
                    <FormattedMessage id="emailaddress">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                    // validate: {
                                    //     checkemail: async (value) => {
                                    //         if (!value) { return true };
                                    //         return await checkEmail(value, schoolID).then(response => {
                                    //             if (response.exists) { return 'EmailAddressAlreadyExist' };
                                    //         });
                                    //     },
                                    //     checkemailfrontend: (value) => { return checkEmailAlreadyUsed(value) }
                                    // },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={<FormattedMessage id="emailaddress" />}>
                                        <Input disabled
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>


                    {/* language settings */}
                    <FormattedMessage id="display-language">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_language"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={NameField}>
                                        <Select
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            <Select.Option key="" value=""><FormattedMessage id="make-choice" /></Select.Option>
                                            <Select.Option key="en" value="en">EN</Select.Option>
                                            <Select.Option key="de" value="de">DE</Select.Option>
                                            <Select.Option key="nl" value="nl">NL</Select.Option>
                                        </Select>
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                </Form>
            </Card>
        </Modal>
    );
};

export default EditUserModal;
