import React, { useState, } from "react";
import { Form, Input, notification, Row, Col, } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import { FormattedMessage, useIntl, } from "react-intl";
import { useForm, Controller, } from "react-hook-form";
import { useLocation, } from 'react-router-dom';
import { UserLogin, } from '../../../services/users';
import { CaretRight, EnvelopeSimple, LockSimple, } from "@phosphor-icons/react";

const LoginFormView = ({
    modalIsOpenHandler,
    setShowForm,
    setSessionData,
    setAuth,
    setLoadingAccountData,
    history,
    ...props
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showMFATokenInput, setShowMFATokenInput] = useState(false);
    const [challengeData, setChallengeData] = useState({});
    const { setCurrentLanguage } = props;
    const intl = useIntl();
    const { pathname } = useLocation();
    const { handleSubmit, control } = useForm({ mode: "all" },);

    const onSubmit = (data) => {
        data.data = challengeData;
        setButtonDisabled(true);
        // setButtonIcon(faSpinner);

        UserLogin(data).then(response => {
            if (response.status) {
                notification['success']({
                    message: 'Inloggen',
                    description: intl.formatMessage({ id: response.msg }),
                });
                setAuth(response.data);
                setLoadingAccountData(true);
                localStorage.setItem('authentication_srs_shop', JSON.stringify(response.data));
                setCurrentLanguage(response.data.user_language.toLowerCase());
                modalIsOpenHandler(false);

                if (pathname === "/") {
                    // redirect to customer dashboard
                    history("/dashboard");
                }

                // Check if last_url has been set, if set redirect to
                // let last_url = localStorage.getItem('last_url');
                // if (last_url) {
                //     history(last_url);
                // } else {
                //     history('/' + response.data.redirect);
                // }
            } else {

                // check if the password needs to changed
                switch (response.data?.ChallengeName) {
                    case "NEW_PASSWORD_REQUIRED":
                        notification['warning']({
                            message: 'Inloggen',
                            description: intl.formatMessage({ id: response.msg }),
                        });
                        setShowForm("CHANGE_PWD");
                        setSessionData(response.data);
                        // history('/change-password',
                        //     { state: { sessiondata: response.data } }
                        // );
                        break;

                    case "SOFTWARE_TOKEN_MFA":
                        notification['warning']({
                            message: 'Inloggen',
                            description: intl.formatMessage({ id: response.msg }),
                        });
                        setButtonDisabled(false);
                        setShowMFATokenInput(true);
                        setChallengeData(response.data);
                        break;

                    default:
                        setButtonDisabled(false);
                        notification['error']({
                            message: 'Inloggen',
                            description: intl.formatMessage({ id: response.msg }),
                        });
                        break;
                };
            }
        })
            .catch(error => {
                console.error('UserLogin error: ', error);

                notification['error']({
                    message: 'Inloggen',
                    description: "Oeps something is wrong: Cannot connect to authentication server!",
                });

                setButtonDisabled(false);
                // setButtonIcon(faSignInAlt);

            })
    };

    // handler for pressing the enter key
    const pressEnterKey = (event) => {
        var enterkey = event.keyCode || event.which;

        // check if enter button has pressed and the form is valid
        if (enterkey === 13) {
            handleSubmit(onSubmit)();
        };
    };

    return (
        <React.Fragment>

            <FormattedMessage id="emailaddress">
                {NameField =>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="user_email"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                message: "EmailAddressNotValid",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <Form.Item
                                help={error && intl.formatMessage({ id: error?.message })}
                                hasFeedback
                                validateStatus={error?.message && "error"}
                                label={<strong>{NameField}</strong>}
                                required>
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    placeholder={NameField}
                                    prefix={<EnvelopeSimple size={18} weight="light" className="site-form-item-icon" />}
                                // onChange={(e) => { props.onChange(e) }}
                                // checked={props.value}
                                />
                            </Form.Item>
                        )}
                    />
                }
            </FormattedMessage>

            <FormattedMessage id="password">
                {NameField =>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="user_password"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                            minLength: { value: 8, message: "inputfield-minLength8" },
                            pattern: {
                                value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                                message: "PasswordMustContainsNumbersLetters",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <Form.Item
                                help={error && intl.formatMessage({ id: error?.message })}
                                hasFeedback
                                validateStatus={error?.message && "error"}
                                label={<strong>{NameField}</strong>}
                                required>
                                <Input.Password
                                    onChange={onChange}
                                    value={value}
                                    placeholder={NameField}
                                    autoComplete="off"
                                    onKeyPress={pressEnterKey}
                                    prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        )}
                    />
                }
            </FormattedMessage>

            {showMFATokenInput && (
                <FormattedMessage id="profile-mfa-authentication-token">
                    {NameField =>
                        <Controller
                            control={control}
                            defaultValue=""
                            name="user_token"
                            rules={{
                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                minLength: { value: 6, message: "inputfield-FieldLength-6number" },
                                maxLength: { value: 6, message: "inputfield-FieldLength-6number" },
                            }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    help={error && intl.formatMessage({ id: error?.message })}
                                    hasFeedback
                                    validateStatus={error?.message && "error"}
                                    label={<strong>{NameField}</strong>}
                                    required>
                                    <Input
                                        onChange={onChange}
                                        value={value}
                                        placeholder={NameField}
                                        onKeyPress={pressEnterKey}
                                        type="number"
                                        prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            )}
                        />
                    }
                </FormattedMessage>
            )}

            <div className="text-center mt-4">
                <div className="btn__box">
                    <button className="btn-one pointer"
                        onClick={handleSubmit(onSubmit)}
                        disabled={buttonDisabled}>
                        <span className="txt text-center">
                            {buttonDisabled ? (
                                <LoadingOutlined />) : (
                                <FormattedMessage id="login" />
                            )}
                        </span>
                    </button>
                </div>
            </div>

            <Row className="mt-3 login-footer">
                <Col span={24}><CaretRight size={18} weight="light" /> <span className="pointer" onClick={e => setShowForm("REQUEST_PWD")}><FormattedMessage id="login-requestpassword" /></span></Col>
                <Col span={24}><CaretRight size={18} weight="light" /> <span className="pointer" onClick={e => setShowForm("SIGN_UP")}><FormattedMessage id="login-signup" /></span></Col>
            </Row>

        </React.Fragment>
    );
};

export default LoginFormView;