import React from "react";
import { Form, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { FormatEuro, DateRangeSelector, } from '../../../components/helpers';


const ProductSideBarDateRangeView = ({
    history,
    totalDays,
    timeslotRentalPeriod,
    selectedDate,
    setSelectedDate,
    ...props
}) => {


    // const [totalDays, setTotalDays] = useState(0);      // The total of day's users want to rent the product
    // const [timeslotRentalPeriods, setTimeslotRentalPeriods] = useState({}); // data.product_pricing.product_time_slots
    const { themeData, } = props;
    const { branding_financial, } = themeData;

    return (

        <Form.Item label={<FormattedMessage id="select-rental-date" />}>
            <DateRangeSelector
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                {...props}
            />

            {selectedDate && branding_financial.minimal_24hours_order && (
                <div className="mb-4">
                    <p style={{ color: "red" }}><small><FormattedMessage id="minimal-24hours-order-warning" /></small></p>
                </div>
            )}

            {selectedDate && timeslotRentalPeriod === undefined && (<span className="text-danger"><FormattedMessage id="select-period-not-available" /></span>)}
            {timeslotRentalPeriod?.ts_interval_pricing?.length === 0 && (<span className="text-danger"><FormattedMessage id="select-period-for-pricing" /></span>)}

            <React.Fragment>
                {timeslotRentalPeriod?.ts_interval_pricing?.map((item, index) => (
                    <div key={index}>
                        {item.interval_amount === 1 && (<div><small><FormattedMessage id="price-per-day" />: <FormatEuro amount={item.interval_price} /></small></div>)}
                        {item.interval_amount > 1 && (<div><small><FormattedMessage id="price-on" /> {item.interval_amount} <FormattedMessage id="days" />: <FormatEuro amount={item.interval_price} /></small></div>)}
                    </div>
                ))}
            </React.Fragment>
        </Form.Item>

    );
};

export default ProductSideBarDateRangeView;