import React from 'react';
import { LoadingOutlined, } from '@ant-design/icons';

const LoadingSpinner = ({
    className
}) => {
    return (
        <div className="pb-5 pt-5 text-center" data-testid="spinner">
            <div className="col-12 mb-r text-center h-100 align-items-center">
                <div className={`spinner-loading align-items-center ${className}`}>
                    <LoadingOutlined />

                    <br></br>
                    Loading...
                </div>
            </div>
        </div>
    );
};

export default LoadingSpinner;