import * as api from '../api';

export const ApplicationStatus = () => {
    return new Promise((resolve, reject) => {
        api
            .get(`/public/status`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};



export const GetCouponCode = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/public/coupon_code`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
};
