import React from "react";
import { Modal, Button, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { ShoppingCart, XCircle, } from "@phosphor-icons/react";

const ProductGotoShoppingcartModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    history,
}) => {


    return (

        <Modal
            open={modalIsOpen}
            title={<><ShoppingCart size={20} weight="light" className="mr-1" /> <FormattedMessage id="goto-shopingcart-added" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}><XCircle size={18} weight="light" className="mr-1" /> <FormattedMessage id="close" /></Button >,
            ]}>

            <div className="text-center mt-4">
                <h5>
                    <strong>
                        <FormattedMessage id="goto-shopingcart-info" />
                    </strong>
                </h5>
            </div>

            <div className="text-center mt-4">
                {/* <Button size="large" onClick={e => { history("/order") }} className="mt-4"><FormattedMessage id="goto-shopingcart" /></Button> */}

                <button className="btn-one"
                    onClick={e => { history("/shoppingcart") }}>
                    <span className="txt"><FormattedMessage id="goto-shopingcart" /></span></button>


            </div>


        </Modal>
    );
};


export default ProductGotoShoppingcartModal;
